import './StoreView.scss';
import React, { useState } from 'react';
import IStore from '../../../models/restful/IStore';
import StorePreview from './StorePreview/StorePreview';
import { generatePath, Link, useParams } from 'react-router-dom';
import { api } from '../../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faRefresh } from '@fortawesome/free-solid-svg-icons';
import PATHS from '../../../paths';

interface IStoreViewProps {
  store?: IStore;
}

const StoreView: React.FunctionComponent<IStoreViewProps> = (
  props: IStoreViewProps
) => {
  const [store, setStore] = useState<IStore>(props.store || ({} as IStore));
  const { id } = useParams();
  const updateFromServer = () => {
    api.read('Store', '' + id).then(resp => {
      if (resp.itemsCollection?.itemsCount) {
        setStore(resp.itemsCollection?.items[0] as IStore);
      }
    });
  };
  if (!store.ID) {
    updateFromServer();
    return null;
  }

  return (
    <div className="store-view-cont">
      <div className="row pt-3 pb-2 mb-3 bstore-bottom">
        <div className="col-6">
          <h3 className="h2 pt-3">Store #{store.ID}</h3>
        </div>
        <div className="col-4"></div>
        <div className="col-2">
          <Link
            to="#"
            className="text-900 me-4 px-0"
            onClick={() => updateFromServer()}
          >
            <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
            Reload
          </Link>
          <Link to={generatePath(PATHS.STORES)} className="text-900 me-4 px-0">
            <FontAwesomeIcon icon={faPalette} className="fs-9 me-2" />
            All stores
          </Link>
        </div>
      </div>
      <StorePreview store={store} />
    </div>
  );
};

export default StoreView;
