import './StoreForm.scss';
import React, { useState } from 'react';
import IStore from '../../../models/restful/IStore';
import { api } from '../../../api/Api';
import { generatePath, Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLink,
  faPalette,
  faRefresh
} from '@fortawesome/free-solid-svg-icons';
import PATHS from '../../../paths';
import Button from '../../../components/base/Button';
import IStoreConnector from '../../../models/restful/IStoreConnector';
import IStoreConnectorGenerateResponse from '../../../models/responces/IStoreConnectorGenerateResponse';

interface IStoreFormProps {
  store?: IStore;
}

const StoreForm: React.FunctionComponent<IStoreFormProps> = (
  props: IStoreFormProps
) => {
  const [store, setStore] = useState<IStore>(props.store || ({} as IStore));
  const [storeConnector, setStoreConnector] = useState<IStoreConnector>(
    {} as IStoreConnector
  );
  const { id } = useParams();
  const updateFromServer = () => {
    api.read('Store', '' + id).then(resp => {
      const store = resp.item as IStore;
      if (store) {
        setStore(store);
        setStoreConnector(store.StoreConnector);
      }
    });
  };
  if (!store.ID) {
    updateFromServer();
    return null;
  }

  const updateStore = (store: IStore) => {
    api.update('Store', store);
  };

  const generate = () => {
    api.patch('StoreConnector', 'generate', storeConnector).then(resp => {
      const sc = resp as IStoreConnectorGenerateResponse;
      if (sc.item.RedirectUrl) window.location.href = sc.item.RedirectUrl;
    });
  };

  return (
    <div className="store-form-cont">
      <div className="row pt-3 pb-2 mb-3 bstore-bottom">
        <div className="col-6">
          <h3 className="h2 pt-3">Store #{store.ID}</h3>
        </div>
        <div className="col-4"></div>
        <div className="col-2">
          <Link
            to="#"
            className="text-900 me-4 px-0"
            onClick={() => updateFromServer()}
          >
            <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
            Reload
          </Link>
          <Link to={generatePath(PATHS.STORES)} className="text-900 me-4 px-0">
            <FontAwesomeIcon icon={faPalette} className="fs-9 me-2" />
            All stores
          </Link>
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-sm-2 col-form-label">
          Title
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            placeholder="My new store"
            defaultValue={store.Title}
            onChange={e => {
              store.Title = e.currentTarget.value;
              updateStore(store);
            }}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-sm-2 col-form-label">
          Description
        </label>
        <div className="col-sm-10">
          <textarea
            className="form-control"
            rows={7}
            placeholder="Description"
            defaultValue={store.Description}
            onChange={e => {
              store.Description = e.currentTarget.value;
              updateStore(store);
            }}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="" className="col-sm-2 col-form-label">
          Connection
        </label>
        <div className="col-sm-10">
          <Button
            variant="link"
            className="text-900 me-4 px-0"
            onClick={() => {
              if (!storeConnector || !storeConnector.ID) {
                api
                  .create('StoreConnector', {
                    StoreID: store.ID
                  } as IStoreConnector)
                  .then(resp => {
                    const sc = resp.item as IStoreConnector;
                    setStoreConnector(sc);
                    generate();
                  });
              }
              if (
                storeConnector &&
                storeConnector.ID &&
                !storeConnector.IsConnected
              )
                generate();
            }}
          >
            <FontAwesomeIcon icon={faLink} className="fs-9 me-2" />
            {(!storeConnector || !storeConnector.IsConnected) && (
              <span>Connect</span>
            )}
            {storeConnector && storeConnector.IsConnected && (
              <span>Disconnect</span>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StoreForm;
