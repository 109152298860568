import { api } from '../../api/Api';
import ITemplateCollection from '../../models/collections/ITemplateCollection';
import ICollectionParams from '../../models/collections/ICollectionParams';

const perPageValues = [10, 20, 50, 100];
const defaultTemplatesCollection: ITemplateCollection = {
  perPage: perPageValues[0],
  pageNo: 1,
  sortColumn: 'ID',
  sortDirection: '-',
  totalCount: 0,
  itemsCount: 0,
  items: []
};

const TemplatesLoader = (
  collectionParams: ICollectionParams = defaultTemplatesCollection
) =>
  api
    .getCollection('Template', collectionParams)
    .then(resp => resp.itemsCollection as ITemplateCollection);

export default TemplatesLoader;
