import { api } from '../../api/Api';
import ICollectionParams from '../../models/collections/ICollectionParams';
import IFontCollection from '../../models/collections/IFontCollection';

const perPageValues = [10, 20, 50, 100];
const defaultFontsCollection: IFontCollection = {
  perPage: perPageValues[0],
  pageNo: 1,
  sortColumn: 'ID',
  sortDirection: '-',
  totalCount: 0,
  itemsCount: 0,
  items: []
};

const FontsLoader = (
  collectionParams: ICollectionParams = defaultFontsCollection
) =>
  api
    .getCollection('Font', collectionParams)
    .then(resp => resp.itemsCollection as IFontCollection);

export default FontsLoader;
