import './TemplateForm.scss';
import React, { useEffect, useState } from 'react';
import ITemplate from '../../../models/restful/ITemplate';
import { api, apiUrl } from '../../../api/Api';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faFloppyDisk,
  faPalette,
  faRefresh,
  faTrash,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import PATHS from '../../../paths';
import DesignBlock from '../../../components/svg/DesignBlock';
import Button from '../../../components/base/Button';
import axios from 'axios';
import IFileCollectionResponse from '../../../models/responces/IFileCollectionResponse';
import IFile from '../../../models/restful/IFile';
import { useSVGByRoot } from '../../../components/svg/hooks/useSVGByRoot';
import IFontCollection from '../../../models/collections/IFontCollection';
import IFont from '../../../models/restful/IFont';
import ITagCollection from '../../../models/collections/ITagCollection';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import IDesign from '../../../models/restful/IDesign';

interface ITemplateFormProps {
  template?: ITemplate;
}

const fontDefaultCollection = {
  pageNo: 1,
  perPage: 200,
  items: [],
  itemsCount: 0,
  totalCount: 0,
  sortColumn: 'Title',
  sortDirection: '+'
} as IFontCollection;

const defaultTagsCollection = {
  pageNo: 1,
  perPage: 200,
  items: [],
  itemsCount: 0,
  totalCount: 0,
  sortColumn: 'Title',
  sortDirection: '+'
} as ITagCollection;

const fonts: IFont[] = [];
const style = document.createElement('style');
document.head.appendChild(style);

const TemplateForm: React.FunctionComponent<ITemplateFormProps> = (
  props: ITemplateFormProps
) => {
  const [template, setTemplate] = useState<ITemplate>(
    props.template || ({} as ITemplate)
  );
  const [fontsCollection, setFontsCollection] = useState<IFontCollection>(
    fontDefaultCollection
  );
  const [tagsCollection, setTagsCollection] = useState<ITagCollection>(
    defaultTagsCollection
  );
  const { id } = useParams();
  const contID = `svg-cont-${id}`;
  const { clear, loadSVG, loadFont, getSVGCode } = useSVGByRoot(contID);
  const navigate = useNavigate();

  const updateFromServer = () => {
    api.getCollection('Font', fontDefaultCollection).then(fontsResp => {
      api.getCollection('Tag', defaultTagsCollection).then(tagsResp => {
        api.read('Template', '' + id).then(resp => {
          if (resp.item) {
            const fontsCollection =
              fontsResp.itemsCollection as IFontCollection;
            const tagsCollection = tagsResp.itemsCollection as ITagCollection;
            setFontsCollection(fontsCollection);
            setTagsCollection(tagsCollection);
            const t = resp.item as ITemplate;
            setTemplate(t);
            window.setTimeout(() => {
              loadSVG(t.SVG, contID);
              fontsCollection.items.forEach(font => {
                const exists = !!fonts.find(f => f.ID === font.ID);
                if (!exists) {
                  style.appendChild(
                    document.createTextNode(
                      '@font-face {font-family: ' +
                        font.FontFamily +
                        ";src: url('" +
                        font.FontPath +
                        "') format('truetype');}"
                    )
                  );
                  loadFont(font.FontFamily, font.FontPath);
                }
              });
            }, 100);
          }
        });
      });
    });
  };

  useEffect(() => {
    window.setTimeout(() => updateFromServer(), 200);
  }, []);

  const updateTemplate = (template: ITemplate) => {
    api.update('Template', template);
  };

  const saveSVG = () => {
    const svg = getSVGCode();
    return api
      .update('Template', {
        ID: template.ID,
        SVG: svg + ''
      } as ITemplate)
      .then(json => {
        // console.log();
        setTemplate(json.item as ITemplate);
      });
  };

  const saveImages = (files: FileList, callback: (files: IFile[]) => void) => {
    const formData = new FormData();
    formData.append('Token', api.getToken());
    formData.append('ID', template.ID + '');
    Array.from(files).forEach((file, idx) => {
      formData.append(`File_${idx}`, file);
    });

    axios({
      method: 'post',
      url: apiUrl() + '/restful/Template',
      data: formData,
      responseType: 'json'
    }).then(resp => {
      callback((resp.data as IFileCollectionResponse).itemsCollection.items);
    });
  };

  if (!template.ID) return null;
  return (
    <div className="template-form-cont">
      <div className="row pt-3 pb-2 mb-3 btemplate-bottom">
        <div className="col-6">
          <h3 className="h2 pt-3">Template #{template.ID}</h3>
        </div>
        <div className="col-6 text-end">
          <Button
            variant="link"
            className="text-900 me-4 px-0 text-danger"
            onClick={() => {
              clear();
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
            Clear
          </Button>
          <Button
            variant="link"
            className="text-900 me-4 px-0 text-primary"
            onClick={() => saveSVG()}
          >
            <FontAwesomeIcon icon={faFloppyDisk} className="fs-9 me-2" />
            Save
          </Button>
          <Button
            type="button"
            className="text-900 me-4 px-0 text-primary btn btn-link btn-upload"
          >
            <input
              type="file"
              accept="image/svg+xml"
              multiple={false}
              onChange={e => {
                if (e.target.files?.length) {
                  const reader = new FileReader();
                  reader.readAsText(e.target.files[0], 'UTF-8');
                  reader.onload = evt =>
                    loadSVG(evt.target?.result + '', contID);
                }
              }}
            />
            <FontAwesomeIcon icon={faUpload} className="fs-9 me-2" />
            Load
          </Button>
          <Button
            className="text-900 me-4 px-0 text-primary btn btn-link"
            onClick={() => {
              saveSVG().then(() => {
                window.open(template.SVGPath, '_blank')?.focus();
              });
            }}
          >
            <FontAwesomeIcon icon={faDownload} className="fs-9 me-2" />
            Download
          </Button>
          <Button
            className="text-900 me-4 px-0 text-primary btn btn-link"
            onClick={() => {
              saveSVG().then(() => {
                window.open(template.PDFPath, '_blank')?.focus();
              });
            }}
          >
            <FontAwesomeIcon icon={faDownload} className="fs-9 me-2" />
            Download PDF
          </Button>
          <Button
            className="text-900 me-4 px-0 text-primary btn btn-link"
            onClick={() => {
              api
                .create('Design', { TemplateID: template.ID } as IDesign)
                .then(resp =>
                  navigate(
                    generatePath(PATHS.DESIGNS_EDIT, {
                      id: resp.item?.ID
                    })
                  )
                );
            }}
          >
            <FontAwesomeIcon icon={faCopy} className="fs-9 me-2" />
            Create design
          </Button>
          <Link
            to="#"
            className="text-900 me-4 px-0"
            onClick={() => updateFromServer()}
          >
            <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
            Reload
          </Link>
          <Link
            to={generatePath(PATHS.TEMPLATES)}
            className="text-900 me-4 px-0"
          >
            <FontAwesomeIcon icon={faPalette} className="fs-9 me-2" />
            All templates
          </Link>
        </div>
      </div>
      <div className="mb-3 row">
        <div className="col-8">
          <DesignBlock
            contID={contID}
            saveImages={saveImages}
            fontsCollection={fontsCollection}
            tagsCollection={tagsCollection}
          />
        </div>
        <div className="col-4">
          <div className="row">
            <label htmlFor="" className="col-sm-2 col-form-label">
              Title
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="My new template"
                defaultValue={template.Title}
                onChange={e => {
                  template.Title = e.currentTarget.value;
                  updateTemplate(template);
                }}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-sm-2 col-form-label">
              Description
            </label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                rows={7}
                placeholder="Description"
                defaultValue={template.Description}
                onChange={e => {
                  template.Description = e.currentTarget.value;
                  updateTemplate(template);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateForm;
