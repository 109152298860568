import './FontForm.scss';
import React, { useEffect, useState } from 'react';
import IFont from '../../../models/restful/IFont';
import { api, apiUrl } from '../../../api/Api';
import { generatePath, Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImagePortrait,
  faPalette,
  faRefresh,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import PATHS from '../../../paths';
import Button from '../../../components/base/Button';
import axios from 'axios';
import IFileCollectionResponse from '../../../models/responces/IFileCollectionResponse';
import IFile from '../../../models/restful/IFile';

interface IFontFormProps {
  font?: IFont;
}

const FontForm: React.FunctionComponent<IFontFormProps> = (
  props: IFontFormProps
) => {
  const [font, setFont] = useState<IFont>(props.font || ({} as IFont));
  const { id } = useParams();

  const updateFromServer = () => {
    api.read('Font', '' + id).then(resp => {
      if (resp.item) {
        const t = resp.item as IFont;
        setFont(t);
      }
    });
  };

  useEffect(() => {
    window.setTimeout(() => updateFromServer(), 200);
  }, []);

  const updateFont = (Font: IFont) => {
    api.update('Font', Font);
  };

  const saveFontFile = (
    files: FileList,
    callback: (files: IFile[]) => void
  ) => {
    const formData = new FormData();
    formData.append('Token', api.getToken());
    formData.append('ID', font.ID + '');
    Array.from(files).forEach((file, idx) => {
      formData.append(`File_${idx}`, file);
    });

    axios({
      method: 'post',
      url: apiUrl() + '/restful/Font',
      data: formData,
      responseType: 'json'
    }).then(resp => {
      callback((resp.data as IFileCollectionResponse).itemsCollection.items);
    });
  };

  const clear = () => {
    console.log('clear');
  };

  if (!font.ID) return null;
  return (
    <div className="font-form-cont">
      <div className="row pt-3 pb-2 mb-3">
        <div className="col-6">
          <h3 className="h2 pt-3">Font #{font.ID}</h3>
        </div>
        <div className="col-6 text-end">
          <Button
            variant="link"
            className="text-900 me-4 px-0 text-danger"
            onClick={() => {
              clear();
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
            Clear
          </Button>
          <Link
            to="#"
            className="text-900 me-4 px-0"
            onClick={() => updateFromServer()}
          >
            <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
            Reload
          </Link>
          <Link to={generatePath(PATHS.FONTS)} className="text-900 me-4 px-0">
            <FontAwesomeIcon icon={faPalette} className="fs-9 me-2" />
            All Fonts
          </Link>
        </div>
      </div>
      <div className="mb-3 row">
        <div className="col-4">
          <div className="row">
            <label htmlFor="" className="col-sm-2 col-form-label">
              Title
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="My new Font"
                defaultValue={font.Title}
                onChange={e => {
                  font.Title = e.currentTarget.value;
                  updateFont(font);
                }}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-sm-2 col-form-label">
              Description
            </label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                rows={7}
                placeholder="Description"
                defaultValue={font.Description}
                onChange={e => {
                  font.Description = e.currentTarget.value;
                  updateFont(font);
                }}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="" className="col-sm-2 col-form-label">
              File
            </label>
            <div className="col-sm-10">
              <button type="button" className="btn btn-primary btn-upload">
                <input
                  type="file"
                  accept="font/ttf"
                  multiple={false}
                  onChange={e => {
                    if (e.target.files) {
                      saveFontFile(e.target.files, () => {
                        updateFromServer();
                      });
                    }
                  }}
                />
                <FontAwesomeIcon icon={faImagePortrait} className="fs-9 me-2" />
                {font.FontPath && <span>Replace file</span>}
                {!font.FontPath && <span>Add file</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FontForm;
