import './StoreConnectorPage.scss';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../../api/Api';
import IStoreConnector from '../../models/restful/IStoreConnector';

interface IStoreConnectorPageProps {}

const StoreConnectorPage: React.FunctionComponent<
  IStoreConnectorPageProps
> = () => {
  const [storeConnector, setStoreConnector] = useState<IStoreConnector>(
    {} as IStoreConnector
  );
  const { id } = useParams();
  const updateFromServer = () => {
    api.read('StoreConnector', '' + id).then(resp => {
      const sc = resp.item as IStoreConnector;
      if (sc) setStoreConnector(sc);
    });
  };

  const connect = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    api
      .patch('StoreConnector', 'connect', storeConnector, {
        code: urlParams.get('code'),
        state: urlParams.get('state')
      })
      .then(resp => {
        const sc = resp.item as IStoreConnector;
        console.log(sc);
      });
  };

  if (!storeConnector.ID) {
    updateFromServer();
    return null;
  }

  connect();

  return (
    <div className="store-connector-cont">
      <h1>Error connecting</h1>
    </div>
  );
};

export default StoreConnectorPage;
