import { Card, Col, Container, Row } from 'react-bootstrap';
import bg37 from 'assets/img/bg/37.png';
import authIllustrations from 'assets/images/img.png';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'components/common/Logo';

interface AuthCardLayoutProps {
  logo?: boolean;
  className?: string;
}

const AuthCardLayout = ({
  logo = true,
  className,
  children
}: PropsWithChildren<AuthCardLayoutProps>) => {
  return (
    <Container fluid className={'bg-300 dark__bg-1200' + className}>
      <div
        className="bg-holder bg-auth-card-overlay"
        style={{ backgroundImage: `url(${bg37})` }}
      />

      <Row className="flex-center position-relative min-vh-100 g-0 py-5">
        <Col xs={11} sm={10} xl={8}>
          <Card className="border border-200 auth-card">
            <Card.Body className="pe-md-0">
              <Row className="align-items-center gx-0 gy-7">
                <Col
                  xs="auto"
                  className="bg-100 dark__bg-1100 rounded-3 position-relative overflow-hidden auth-title-box"
                >
                  <img
                    src={authIllustrations}
                    alt=""
                    style={{ maxWidth: '100%' }}
                  />
                </Col>
                <Col className="mx-auto">
                  {logo && (
                    <div className="text-center">
                      <Link
                        to="/"
                        className="d-inline-block text-decoration-none mb-4"
                      >
                        <Logo
                          text={false}
                          width={58}
                          className="fw-bolder fs-5 d-inline-block"
                        />
                      </Link>
                    </div>
                  )}
                  <div className="auth-form-box">{children}</div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthCardLayout;
