const PATHS = {
  HOME: '/',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  TEMPLATES: '/templates',
  TEMPLATES_VIEW: '/templates/view/:id',
  TEMPLATES_EDIT: '/templates/edit/:id',
  DESIGNS: '/designs',
  DESIGNS_VIEW: '/designs/view/:id',
  DESIGNS_EDIT: '/designs/edit/:id',
  FONTS: '/fonts',
  FONTS_VIEW: '/fonts/view/:id',
  FONTS_EDIT: '/fonts/edit/:id',
  ORDERS: '/orders',
  ORDERS_VIEW: '/orders/view/:name',
  ORDERS_EDIT: '/orders/edit/:name',
  STORES: '/stores',
  STORE_VIEW: '/stores/view/:id',
  STORE_EDIT: '/stores/edit/:id',
  STORE_CONNECTOR: '/store-connect/:id'
};

export default PATHS;
