import { Element } from '@svgdotjs/svg.js';

export const useBBox = () => {
  const coo = { x: 0, y: 0 };

  const OBBox = {
    x0: 0,
    y0: 0,
    x1: 0,
    y1: 0,
    w: 0,
    h: 0,
    cx: 0,
    cy: 0
  };

  const bbox = (elem: Element) => {
    const ds = { ...OBBox };
    ds.cx = elem.cx();
    ds.cy = elem.cy();
    const bbox = elem.rbox();
    ds.w = bbox.w;
    ds.h = bbox.h;
    ds.x0 = ds.cx - ds.w / 2;
    ds.y0 = ds.cy - ds.h / 2;
    ds.x1 = ds.cx + ds.w / 2;
    ds.y1 = ds.cy + ds.h / 2;
    return ds;
  };

  const bboxElements = (elems: Element[]) => {
    if (!elems.length) return { ...bbox };
    const dss = bbox(elems[0]);
    elems.forEach(el => {
      const ds = bbox(el);
      if (ds.x0 < dss.x0) dss.x0 = ds.x0;
      if (ds.y0 < dss.y0) dss.y0 = ds.y0;
      if (ds.x1 > dss.x1) dss.x1 = ds.x1;
      if (ds.y1 > dss.y1) dss.y1 = ds.y1;
      ds.cx = dss.x1 + (dss.x1 - dss.x0) / 2;
      ds.cy = dss.y1 + (dss.y1 - dss.y0) / 2;
      ds.w = dss.x1 - dss.x0;
      ds.h = dss.y1 - dss.y0;
    });
    return dss;
  };

  return {
    bbox,
    bboxElements,
    coo
  };
};
