export type TCoo = { x: number; y: number };
export type TDistance = { dx: number; dy: number; dr: number; rotate: number };
export const useMath = () => {
  const distance = (pos1: TCoo, pos2: TCoo) => {
    const dx = pos2.x - pos1.x;
    const dy = pos2.y - pos1.y;
    const dr = Math.sqrt(dx ** 2 + dy ** 2);
    let rotate = -(Math.atan(dx / dy) * 180) / 3.1415;
    if (dx > 0 && dy > 0) rotate += 180;
    if (dx < 0 && dy > 0) rotate -= 90;
    if (dx < 0 && dy > 0) rotate += 270;
    return { dx, dy, dr, rotate } as TDistance;
  };

  return {
    distance
  };
};
