import './DesignPreview.scss';
import React from 'react';
import IDesign from '../../../../models/restful/IDesign';

interface IDesignViewProps {
  design: IDesign;
}

const DesignPreview: React.FunctionComponent<IDesignViewProps> = (
  props: IDesignViewProps
) => {
  const design = props.design;

  return (
    <div className="design-preview-cont">
      <h4>Title:</h4>
      <p>{design.Title}</p>
      <h5>Description:</h5>
      <p>{design.Description}</p>
    </div>
  );
};

export default DesignPreview;
