import {
  Element,
  Circle,
  G,
  Line,
  Rect,
  StrokeData,
  MatrixExtract
} from '@svgdotjs/svg.js';
import { TCoo } from './useMath';

export interface IControlCircles {
  lt: Circle;
  ct: Circle;
  rt: Circle;
  lc: Circle;
  cc: Circle;
  rc: Circle;
  lb: Circle;
  cb: Circle;
  rb: Circle;
}

export interface IControlRotation {
  rot: Circle;
  rotLine: Line;
}

export interface IControlRect {
  rect: Rect;
  bbRect: Rect;
}

export interface IControlBound extends IControlCircles, IControlRect {}

export interface IControlGroup extends IControlBound, IControlRotation {}

export interface IElemGroup {
  elem: Element;
  g: G;
  cs: IControlCircles | IControlBound | IControlGroup;
  selected: boolean;
  startPos: TCoo;
  startCenterPos: TCoo;
  startTr: MatrixExtract;
}

export const useFactory = () => {
  const c = new Circle();
  c.radius(8).fill('#22f').stroke('#444');

  const createAnchors = () => {
    return {
      lt: c.clone().addClass('image-edit-anchor lt') as Circle,
      ct: c.clone().addClass('image-edit-anchor ct') as Circle,
      rt: c.clone().addClass('image-edit-anchor rt') as Circle,
      lc: c.clone().addClass('image-edit-anchor lc') as Circle,
      cc: c.clone().addClass('image-edit-anchor cc').fill('#aa2222') as Circle,
      rc: c.clone().addClass('image-edit-anchor rc') as Circle,
      lb: c.clone().addClass('image-edit-anchor lb') as Circle,
      cb: c.clone().addClass('image-edit-anchor cb') as Circle,
      rb: c.clone().addClass('image-edit-anchor rb') as Circle
    } as IControlCircles;
  };

  const createRect = () => {
    return {
      rect: new Rect()
        .addClass('image-edit-border')
        .stroke({ color: '#22f', width: '5px' } as unknown as StrokeData)
        .fill('none'),
      bbRect: new Rect()
        .addClass('image-edit-border')
        .stroke({ color: '#888', width: '2px' } as unknown as StrokeData)
        .fill('none')
    } as IControlRect;
  };

  const createRot = () => {
    return {
      rot: c.clone().addClass('image-edit-anchor rot').fill('#d0d000'),
      rotLine: new Line()
        .addClass('image-edit-anchor rot-line')
        .stroke({ color: '#22f', width: '5px' } as unknown as StrokeData)
    } as IControlRotation;
  };

  const createBound = () => {
    return { ...createAnchors(), ...createRect() } as IControlBound;
  };

  const createGroup = () => {
    return { ...createBound(), ...createRot() } as IControlGroup;
  };

  const createSVGGroupCircles = (controlCircles?: IControlCircles) => {
    const cs = controlCircles ? controlCircles : createAnchors();
    const g = new G();
    cs.lt.addTo(g);
    cs.ct.addTo(g);
    cs.rt.addTo(g);
    cs.lc.addTo(g);
    cs.cc.addTo(g);
    cs.rc.addTo(g);
    cs.lb.addTo(g);
    cs.cb.addTo(g);
    cs.rb.addTo(g);
    return { g: g, cs: cs };
  };

  const createSVGGroupBound = (controlBound?: IControlBound) => {
    const cs = controlBound ? controlBound : createBound();
    const g = createSVGGroupCircles(cs).g;
    cs.rect.addTo(g);
    cs.rect.back();
    return { g: g, cs: cs };
  };

  const createSVGGroupControls = (controlGroup?: IControlGroup) => {
    const cs = controlGroup ? controlGroup : createGroup();
    const g = createSVGGroupBound(cs).g;
    cs.rotLine.addTo(g);
    cs.rot.addTo(g);
    return { g: g, cs: cs };
  };

  const createItem = (
    elem: Element,
    g: G,
    cs: IControlCircles | IControlBound | IControlGroup
  ) => {
    return {
      elem,
      g,
      cs,
      selected: false,
      startPos: { x: elem.x(), y: elem.y() },
      startCenterPos: { x: elem.cx(), y: elem.cy() },
      startTr: elem.transform()
    } as IElemGroup;
  };

  return {
    createAnchors,
    createRect,
    createRot,
    createBound,
    createGroup,
    createSVGGroupCircles,
    createSVGGroupBound,
    createSVGGroupControls,
    createItem
  };
};
