import IOrder from '../../../models/restful/IOrder';

export const productsValid = (order: IOrder) => {
  return !!order?.OrderJSON?.products?.length;
};

export const designsValid = (order: IOrder) => {
  const emptyItem = order.OrderJSON.products.find(item =>
    item.variations.find(
      v => !v.ModelID || !v.ColorID || !v.SizeID || v.Quantity < 1
    )
  );
  return !emptyItem && !!order?.Design?.SVG;
};

export const shipmentValid = (order: IOrder) => {
  const shoi = order?.ShipmentOrderItem;
  if (!shoi) return false;
  return shoi.FirstName && shoi.LastName && shoi.Email;
};

export const confirmValid = (order: IOrder) => !!order.Title;
