import React from 'react';
import './DesignsActions.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faList,
  faPencil,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { api } from '../../../api/Api';
import PATHS from '../../../paths';
import IDesign from '../../../models/restful/IDesign';
import { DesignsPageSettings } from '../DesignsPage';

interface IDesignsActionsProps {
  design: IDesign;
  updateFromServer(): void;
}

const DesignsActions: React.FunctionComponent<IDesignsActionsProps> = props => {
  const design = props.design;
  const navigate = useNavigate();

  const duplicateDesign = () => {
    api.create('Design', { ...design, ID: 0 }).then(resp => {
      const st = resp.item as IDesign;
      DesignsPageSettings.isUpdateNeeded = true;
      navigate(generatePath(PATHS.DESIGNS_EDIT, { id: st.ID }));
    });
  };

  const deleteDesign = () => {
    api.delete('Design', [design.ID]).then(() => {
      props.updateFromServer();
    });
  };

  return (
    <>
      <Link
        className="btn btn-outline-secondary btn-sm design-action-btn"
        to={generatePath(PATHS.DESIGNS_VIEW, { id: design.ID })}
      >
        <FontAwesomeIcon icon={faList} className="ms-1 fs-9" />
        &nbsp; View
      </Link>
      <Link
        className="btn btn-outline-primary btn-sm design-action-btn"
        to={generatePath(PATHS.DESIGNS_EDIT, { id: design.ID })}
      >
        <FontAwesomeIcon icon={faPencil} className="ms-1 fs-9" />
        &nbsp; Edit
      </Link>
      <button
        className="btn btn-outline-success btn-sm design-action-btn"
        onClick={() =>
          window.confirm('Really copy design?') ? duplicateDesign() : null
        }
      >
        <FontAwesomeIcon icon={faCopy} className="ms-1 fs-9" />
        &nbsp; Copy
      </button>
      <button
        className="btn btn-outline-danger btn-sm design-action-btn"
        onClick={() =>
          window.confirm('Really delete design?') ? deleteDesign() : null
        }
      >
        <FontAwesomeIcon icon={faTrash} className="ms-1 fs-9" />
        &nbsp; Delete
      </button>
    </>
  );
};

export default DesignsActions;
