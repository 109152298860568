import { api } from '../../api/Api';
import ICollectionParams from '../../models/collections/ICollectionParams';
import IOrderCollection from '../../models/collections/IOrderCollection';

const perPageValues = [10, 20, 50, 100];
const defaultOrdersCollection: IOrderCollection = {
  perPage: perPageValues[0],
  pageNo: 1,
  sortColumn: 'ID',
  sortDirection: '-',
  totalCount: 0,
  itemsCount: 0,
  items: []
};

const OrdersLoader = (
  collectionParams: ICollectionParams = defaultOrdersCollection
) =>
  api
    .getCollection('Order', collectionParams)
    .then(resp => resp.itemsCollection as IOrderCollection);

export default OrdersLoader;
