import { IUseSVG, useSVG } from './useSVG';

export interface SVGItem {
  root: string;
  useSVG: IUseSVG;
}
let pool: SVGItem[] = [];

const getByRoot = (root: string) => {
  return pool.find(item => item.root === root);
};
const removeByRoot = (root: string) => {
  pool = pool.filter(item => item.root !== root);
};

const addByRoot = (root: string) => {
  removeByRoot(root);
  const item = {
    root: root,
    useSVG: useSVG()
  };
  pool.push(item);
  return item;
};

const loadOrCreateByRoot = (root: string) => {
  return getByRoot(root) || addByRoot(root);
};

export const useSVGByRoot = (root: string) => {
  return loadOrCreateByRoot(root).useSVG;
};
