import './OrderView.scss';
import React, { useState } from 'react';
import IOrder from '../../../models/restful/IOrder';
import OrderPreview from './OrderPreview/OrderPreview';
import { generatePath, Link, useParams } from 'react-router-dom';
import { api } from '../../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faRefresh } from '@fortawesome/free-solid-svg-icons';
import PATHS from '../../../paths';

interface IOrderViewProps {
  order?: IOrder;
}

const OrderView: React.FunctionComponent<IOrderViewProps> = (
  props: IOrderViewProps
) => {
  const [order, setOrder] = useState<IOrder>(props.order || ({} as IOrder));
  const { id } = useParams();
  const updateFromServer = () => {
    api.read('Order', '' + id).then(resp => {
      if (resp.item) {
        setOrder(resp.item as IOrder);
      }
    });
  };
  if (!order.ID) {
    updateFromServer();
    return null;
  }

  return (
    <div className="order-view-cont">
      <div className="row pt-3 pb-2 mb-3 border-bottom">
        <div className="col-6">
          <h3 className="h2 pt-3">Order #{order.ID}</h3>
        </div>
        <div className="col-4"></div>
        <div className="col-2">
          <Link
            to="#"
            className="text-900 me-4 px-0"
            onClick={() => updateFromServer()}
          >
            <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
            Reload
          </Link>
          <Link to={generatePath(PATHS.ORDERS)} className="text-900 me-4 px-0">
            <FontAwesomeIcon icon={faPalette} className="fs-9 me-2" />
            All orders
          </Link>
        </div>
      </div>
      <OrderPreview order={order} />
    </div>
  );
};

export default OrderView;
