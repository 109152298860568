import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import OrderDetailsTable from './OrderDetailsTable';
import OrderDetailsSummaryCard from './OrderDetailsSummaryCard';
import OrderInfoItem from '../../../components/info-items/OrderInfoItem';
import IOrder from '../../../models/restful/IOrder';

interface IOrderDetailsProps {
  order: IOrder;
  hideTitle?: boolean;
}

const OrderDetails: React.FunctionComponent<IOrderDetailsProps> = props => {
  const order = props.order;

  return (
    <div>
      <div className="mb-9">
        {!props.hideTitle && (
          <>
            <h2 className="mb-1">
              Order <span>{order?.Name}</span>
            </h2>
            <hr />
          </>
        )}

        <Row>
          <Col xs={12} xl={8} xxl={9}>
            <h4 className="mb-5">Shipping details</h4>
            <Row className="gx-4 gy-6 g-xl-7 justify-content-sm-center justify-content-xl-start">
              <Col xs={12} sm="auto">
                <Row className="g-4 flex-sm-column">
                  <Col md={6} xs={6} sm={12}>
                    <OrderInfoItem icon="mail" label="Email" />
                    <Link
                      className="fs-9 ms-4"
                      to={'mailto:' + order.ShipmentOrderItem?.Email}
                    >
                      {order.ShipmentOrderItem?.Email}
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm="auto">
                <Row className="g-4 flex-sm-column">
                  <Col md={4} xs={6} sm={12}>
                    <OrderInfoItem icon="home" label="To" />
                    <div className="ms-4">
                      <p className="text-800 mb-0 fs-9">
                        {`${order.ShipmentOrderItem?.FirstName} ${order.ShipmentOrderItem?.LastName}`}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="g-5 gy-7">
              <Col xs={12} xl={8} xxl={9}>
                <div className="mb-6">
                  <OrderDetailsTable order={order} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} xl={4} xxl={3}>
            <OrderDetailsSummaryCard order={order} />
            <Card>
              <Card.Body>
                <Card.Title as="h3" className="mb-4">
                  Order Status
                </Card.Title>
                <h6 className="mb-2">Payment status</h6>
                <p>{order.PaymentStatus}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <hr />
      </div>
    </div>
  );
};

export default OrderDetails;
