import React from 'react';
import './FontsActions.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faList,
  faPencil,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { api } from '../../../api/Api';
import PATHS from '../../../paths';
import IFont from '../../../models/restful/IFont';
import { fontsPageSettings } from '../FontsPage';

interface IFontsActionsProps {
  Font: IFont;
  updateFromServer(): void;
}

const FontsActions: React.FunctionComponent<IFontsActionsProps> = props => {
  const Font = props.Font;
  const navigate = useNavigate();

  const duplicateFont = () => {
    api.create('Font', { ...Font, ID: 0 }).then(resp => {
      const st = resp.item as IFont;
      fontsPageSettings.isUpdateNeeded = true;
      navigate(generatePath(PATHS.FONTS_EDIT, { id: st.ID }));
    });
  };

  const deleteFont = () => {
    api.delete('Font', [Font.ID]).then(() => {
      props.updateFromServer();
    });
  };

  return (
    <>
      <Link
        className="btn btn-outline-secondary btn-sm Font-action-btn"
        to={generatePath(PATHS.FONTS_VIEW, { id: Font.ID })}
      >
        <FontAwesomeIcon icon={faList} className="ms-1 fs-9" />
        &nbsp; View
      </Link>
      <Link
        className="btn btn-outline-primary btn-sm Font-action-btn"
        to={generatePath(PATHS.FONTS_EDIT, { id: Font.ID })}
      >
        <FontAwesomeIcon icon={faPencil} className="ms-1 fs-9" />
        &nbsp; Edit
      </Link>
      <button
        className="btn btn-outline-success btn-sm Font-action-btn"
        onClick={() =>
          window.confirm('Really copy Font?') ? duplicateFont() : null
        }
      >
        <FontAwesomeIcon icon={faCopy} className="ms-1 fs-9" />
        &nbsp; Copy
      </button>
      <button
        className="btn btn-outline-danger btn-sm Font-action-btn"
        onClick={() =>
          window.confirm('Really delete Font?') ? deleteFont() : null
        }
      >
        <FontAwesomeIcon icon={faTrash} className="ms-1 fs-9" />
        &nbsp; Delete
      </button>
    </>
  );
};

export default FontsActions;
