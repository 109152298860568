import './Product.scss';
import React, { useState } from 'react';
import IMerchProduct from '../../../../../models/restful/IMerchProduct';
import IOrderExtended from '../../../../../models/restful/IOrderExtended';

interface IProductsProps {
  order: IOrderExtended;
  product: IMerchProduct;
  onChange?(b: boolean): void;
}

const Product: React.FunctionComponent<IProductsProps> = (
  props: IProductsProps
) => {
  const product = props.product;
  const [checked, setChecked] = useState<boolean>(
    !!props.order.OrderJSON.products.find(
      item => item.product.ID === product.ID
    )
  );

  const toggleSelectedProduct = (b: boolean) => {
    const json = props.order.OrderJSON;
    if (b)
      json.products.push({
        product: product,
        variations: [
          { ModelID: 0, ColorID: 0, SizeID: 0, Quantity: 1, Title: '' }
        ]
      });
    else json.products = json.products.filter(p => p.product.ID !== product.ID);
    setChecked(b);
    if (props.onChange) props.onChange(b);
  };

  const src = product?.PreviewImageUrl;
  return (
    <div className="col-2 mb-3">
      <div className="card">
        <span
          style={{
            display: 'inline-block',
            height: '120px',
            backgroundImage: `url("${src}")`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
          onClick={() => toggleSelectedProduct(!checked)}
          title={product.Title}
        ></span>
        <div className="card-body" style={{ padding: '7px' }}>
          <h5
            className="card-title"
            style={{ height: '40px', overflow: 'hidden' }}
          >
            {product.Title}
          </h5>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => toggleSelectedProduct(!checked)}
          >
            {!checked ? 'Add to cart' : 'Remove'}
          </button>
        </div>
        <input
          type="checkbox"
          className="puduct-check-input"
          checked={checked}
          onChange={() => toggleSelectedProduct(!checked)}
        />
      </div>
    </div>
  );
};

export default Product;
