import React from 'react';
import './OrdersActions.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faList,
  faPencil,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { generatePath, Link } from 'react-router-dom';
import { api } from '../../../api/Api';
import PATHS from '../../../paths';
import IOrder from '../../../models/restful/IOrder';
// import { OrdersPageSettings } from '../OrdersPage';

interface IOrdersActionsProps {
  order: IOrder;
  updateFromServer(): void;
}

const OrdersActions: React.FunctionComponent<IOrdersActionsProps> = props => {
  const order = props.order;
  // const navigate = useNavigate();

  const duplicateOrder = () => {
    console.log(duplicateOrder);
    // api.create('Order', { ...order, ID: 0 }).then(resp => {
    //   const st = resp.item as IOrder;
    //   OrdersPageSettings.isUpdateNeeded = true;
    //   navigate(generatePath(PATHS.ORDERS_EDIT, { name: st.Name }));
    // });
  };

  const deleteOrder = () => {
    api.delete('Order', [order.ID]).then(() => {
      props.updateFromServer();
    });
  };

  return (
    <>
      <Link
        className="btn btn-outline-secondary btn-sm order-action-btn"
        to={generatePath(PATHS.ORDERS_VIEW, { name: order.Name })}
      >
        <FontAwesomeIcon icon={faList} className="ms-1 fs-9" />
        &nbsp; View
      </Link>
      <Link
        className="btn btn-outline-primary btn-sm order-action-btn"
        to={generatePath(PATHS.ORDERS_EDIT, { name: order.Name })}
      >
        <FontAwesomeIcon icon={faPencil} className="ms-1 fs-9" />
        &nbsp; Edit
      </Link>
      <button
        className="btn btn-outline-success btn-sm order-action-btn"
        onClick={() =>
          window.confirm('Really copy order?') ? duplicateOrder() : null
        }
      >
        <FontAwesomeIcon icon={faCopy} className="ms-1 fs-9" />
        &nbsp; Copy
      </button>
      <button
        className="btn btn-outline-danger btn-sm order-action-btn"
        onClick={() =>
          window.confirm('Really delete order?') ? deleteOrder() : null
        }
      >
        <FontAwesomeIcon icon={faTrash} className="ms-1 fs-9" />
        &nbsp; Delete
      </button>
    </>
  );
};

export default OrdersActions;
