import { Element, Style, Svg, SVG, Text } from '@svgdotjs/svg.js';
import { useFitting } from './useFitting';
import { TPosition, useTransform } from './useTransform';
import { SELECTED_CLASS, TOUCHED_CLASS, useSelected } from './useSelected';

export type TTextProperty =
  | 'font-family'
  | 'data-font-family'
  | 'text'
  | 'color';
export type TUseSVGOnSelect = (elem: Element) => void;
export type TUseSVGOnDeselect = () => void;

export interface IUseSVG {
  loadSVG(svgString: string, root: string): void;
  loadFont(fontFace: string, url: string): void;
  addToDom(root: string): void;
  clear(): void;
  clearSelection(): void;
  removeSelection(): void;
  cleanSelected(): void;
  addOnselectEventListener(listener: TUseSVGOnSelect): void;
  addOndeselectEventListener(listener: TUseSVGOnDeselect): void;
  dropImage(url: string): void;
  dropText(text: string, color: string, fontFamily: string): void;
  applyTextProp(textProp: TTextProperty, value: string): void;
  applyTag(tagID: number): void;
  fitSelectedToWidth(): void;
  fitSelectedToHeight(): void;
  applyTransform(position: TPosition): void;
  applyRotation(alpha: number): void;
  selectedToUp(): void;
  selectedToDown(): void;
  getSVGCode(): string;
}
export const useSVG = () => {
  const { fitWidth, fitHeight } = useFitting();
  const { applyAlign, applyTransformRotation, cleanTransformation } =
    useTransform();
  const { setSvg, selectElement, deselect, updateGroup, removeSelected } =
    useSelected();

  let svg: Svg = SVG();

  let selected: Element | null = null;
  const onSelectListeners: TUseSVGOnSelect[] = [];
  const onDeselectListeners: TUseSVGOnDeselect[] = [];

  const loadSVG = (svgString: string, root: string) => {
    svg = svgString.length > 0 ? (SVG(svgString) as Svg) : SVG();
    addToDom(root);
  };

  const loadFont = (fontFace: string, url: string) => {
    const style = (svg.findOne('style') || svg.style()) as Style;
    style.font(fontFace, url);
  };

  const addToDom = (root: string) => {
    const container: HTMLElement | null = document.getElementById(root);
    if (container) {
      container.innerHTML = '';
      svg.id(container.id + '-svg-root');
      svg.addTo(container);
      svg.size(container.clientWidth, container.clientWidth * 0.75);
      svg.find('image,text').each(block => {
        block.removeClass(SELECTED_CLASS);
        block.removeClass(TOUCHED_CLASS);
        block.on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          clearSelection();
          setSelected(block);
        });
      });
      setSvg(svg);
    }
  };

  const clear = () => {
    svg.clear();
    clearSelection();
  };

  const setSelected = (elem: Element) => {
    selectElement((selected = elem));
    onSelectListeners.forEach(listener => listener(elem));
  };

  const clearSelection = () => {
    deselect();
    selected = null;
    onDeselectListeners.forEach(listener => listener());
  };

  const addOnselectEventListener = (listener: TUseSVGOnSelect) =>
    onSelectListeners.push(listener);

  const addOndeselectEventListener = (listener: TUseSVGOnDeselect) =>
    onDeselectListeners.push(listener);

  const dropImage = (url: string) => {
    const image = svg.image(url, () => {
      image.on('click', e => {
        e.preventDefault();
        e.stopPropagation();
        clearSelection();
        setSelected(image);
      });
      setSelected(image);
      if (image.width() > svg.width()) fitSelectedToWidth();
      if (image.height() > svg.height()) fitSelectedToHeight();
      applyTransform('CC');
    });
  };

  const dropText = (text: string, color: string, fontFamily: string) => {
    const t = svg
      .text(text)
      .fill(color)
      .font('family', fontFamily)
      .font('size', '32px');

    t.on('click', e => {
      e.preventDefault();
      e.stopPropagation();
      clearSelection();
      setSelected(t);
    });
    setSelected(t);
    if (t.width() > svg.width()) fitSelectedToWidth();
    if (t.height() > svg.height()) fitSelectedToHeight();
    applyTransform('CC');
  };

  const applyTextProp = (textProp: TTextProperty, value: string) => {
    const sel: Text = selected as Text;
    if (!sel || !sel.text) return;
    if (textProp === 'font-family') sel.font('family', value);
    if (textProp === 'data-font-family') sel.attr('data-font-family', value);
    if (textProp === 'text') sel.text(value);
    if (textProp === 'color') sel.fill(value);
    updateGroup();
  };

  const fitSelectedToWidth = () => {
    if (selected) {
      fitWidth(selected, svg);
      updateGroup();
    }
  };
  const fitSelectedToHeight = () => {
    if (selected) {
      fitHeight(selected, svg);
      updateGroup();
    }
  };
  const applyTransform = (position: TPosition) => {
    if (selected) {
      applyAlign(selected, svg, position);
      updateGroup();
    }
  };

  const applyRotation = (alpha: number) => {
    if (selected) {
      applyTransformRotation(selected, alpha);
      updateGroup();
    }
  };

  const applyTag = (tagID: number) => {
    if (selected) {
      selected.data('tagid', tagID);
    }
  };

  const removeSelection = () => removeSelected();

  const cleanSelected = () => {
    if (selected) {
      cleanTransformation(selected);
      updateGroup();
    }
  };

  const selectedToUp = () => {
    const sel = selected;
    deselect();
    if (sel) {
      sel.forward();
      setSelected(sel);
    }
  };
  const selectedToDown = () => {
    const sel = selected;
    deselect();
    if (sel) {
      sel.backward();
      setSelected(sel);
    }
  };

  const getSVGCode = () => {
    const sel = selected;
    deselect();
    const code = svg.svg();
    if (sel) setSelected(sel);
    return code;
  };

  return {
    loadSVG,
    loadFont,
    addToDom,
    clear,
    clearSelection,
    removeSelection,
    cleanSelected,
    addOnselectEventListener,
    addOndeselectEventListener,
    dropImage,
    dropText,
    applyTextProp,
    applyTag,
    fitSelectedToWidth,
    fitSelectedToHeight,
    applyTransform,
    applyRotation,
    selectedToUp,
    selectedToDown,
    getSVGCode
  } as IUseSVG;
};
