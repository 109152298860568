import React from 'react';
import { Card } from 'react-bootstrap';
import IOrder from '../../../models/restful/IOrder';

interface IOrderDetailsSummaryCardProps {
  order: IOrder;
}
const OrderDetailsSummaryCard: React.FunctionComponent<
  IOrderDetailsSummaryCardProps
> = props => {
  const order = props.order;
  const src = order.Design.SVGThumbPath;
  return (
    <Card>
      <Card.Body>
        <Card.Title as="h3" className="mb-4">
          Summary
        </Card.Title>
        <img src={src} alt="" className="img-thumbnail" />
        <hr />
        {order.OrderJSON.products.map((item, idx) => {
          const product = item.product;
          const variations = item.variations;
          return (
            <div key={idx}>
              <h5>
                <strong>{product.Title}</strong>
              </h5>
              {variations.map((v, vidx) => {
                return (
                  <div className="row" key={vidx}>
                    <div className="col-10">{v.Title}</div>
                    <div className="col-2 text-end">x {v.Quantity}</div>
                    <hr />
                  </div>
                );
              })}
            </div>
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default OrderDetailsSummaryCard;
