import './StorePreview.scss';
import React from 'react';
import IStore from '../../../../models/restful/IStore';

interface IStoreViewProps {
  store: IStore;
}

const StorePreview: React.FunctionComponent<IStoreViewProps> = (
  props: IStoreViewProps
) => {
  const store = props.store;

  return (
    <div className="store-preview-cont">
      <p>
        <h4>Title:</h4>
        &nbsp;
        {store.Title}
      </p>
      <div className="row">
        <div className="col-12">
          <h5>Description:</h5>
          {store.Description}
        </div>
      </div>
    </div>
  );
};

export default StorePreview;
