import { api } from '../../api/Api';
import ICollectionParams from '../../models/collections/ICollectionParams';
import IStoreCollection from '../../models/collections/IStoreCollection';

const perPageValues = [10, 20, 50, 100];
const defaultOrderCollection: IStoreCollection = {
  perPage: perPageValues[0],
  pageNo: 1,
  sortColumn: 'ID',
  sortDirection: '-',
  totalCount: 0,
  itemsCount: 0,
  items: []
};

const StoreConnectorLoader = (
  collectionParams: ICollectionParams = defaultOrderCollection
) =>
  api
    .getCollection('Store', collectionParams)
    .then(resp => resp.itemsCollection as IStoreCollection);

export default StoreConnectorLoader;
