import './TemplatePreview.scss';
import React from 'react';
import ITemplate from '../../../../models/restful/ITemplate';

interface ITemplateViewProps {
  template: ITemplate;
}

const TemplatePreview: React.FunctionComponent<ITemplateViewProps> = (
  props: ITemplateViewProps
) => {
  const template = props.template;

  return (
    <div className="template-preview-cont">
      <h4>Title:</h4>
      <p>{template.Title}</p>
      <h5>Description:</h5>
      <p>{template.Description}</p>
    </div>
  );
};

export default TemplatePreview;
