import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React from 'react';
import IOrder, { IVariation } from '../../../models/restful/IOrder';
import IMerchProduct from '../../../models/restful/IMerchProduct';

interface IOrderDetailsTableProps {
  order: IOrder;
}

interface IRow {
  product: IMerchProduct;
  variation: IVariation;
}

const columns: ColumnDef<IRow>[] = [
  {
    id: 'Mockup',
    accessorKey: '',
    cell: ({ row: { original } }) => {
      const src = original.product.PreviewImageUrl;
      return (
        <div className="rounded-2 border d-inline-block">
          <img src={src} alt="" width={53} />
        </div>
      );
    },
    meta: { cellProps: { className: 'py-2' } }
  },
  {
    accessorKey: 'Title',
    header: 'Product',
    cell: ({ row: { original } }) => original.product.Title,
    meta: {
      headerProps: { style: { minWidth: 380 } },
      cellProps: { className: '' }
    }
  },
  {
    header: 'Variation',
    cell: ({ row: { original } }) => original.variation.Title,
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'white-space-nowrap text-900 ps-4' }
    }
  },
  {
    header: 'Quantity',
    cell: ({ row: { original } }) => original.variation.Quantity,
    meta: {
      headerProps: { style: { width: 200 }, className: 'ps-4 text-end' },
      cellProps: { className: 'text-end ps-4 text-700' }
    }
  }
];

const OrderDetailsTable: React.FunctionComponent<
  IOrderDetailsTableProps
> = props => {
  const order = props.order;
  const rows: IRow[] = [];
  order.OrderJSON.products.forEach(item =>
    item.variations.forEach(v =>
      rows.push({ product: item.product, variation: v })
    )
  );
  const table = useAdvanceTable({
    data: rows || [],
    columns,
    pageSize: 24,
    pagination: true,
    sortable: true
  });
  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="border-y">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default OrderDetailsTable;
