import React from 'react';
import './TemplatesActions.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faList,
  faPencil,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { api } from '../../../api/Api';
import PATHS from '../../../paths';
import ITemplate from '../../../models/restful/ITemplate';
import { TemplatesPageSettings } from '../TemplatesPage';

interface ITemplatesActionsProps {
  template: ITemplate;
  updateFromServer(): void;
}

const TemplatesActions: React.FunctionComponent<
  ITemplatesActionsProps
> = props => {
  const template = props.template;
  const navigate = useNavigate();

  const duplicateTemplate = () => {
    api.create('Template', { ...template, ID: 0 }).then(resp => {
      const st = resp.item as ITemplate;
      TemplatesPageSettings.isUpdateNeeded = true;
      navigate(generatePath(PATHS.TEMPLATES_EDIT, { id: st.ID }));
    });
  };

  const deleteTemplate = () => {
    api.delete('Template', [template.ID]).then(() => {
      props.updateFromServer();
    });
  };

  return (
    <>
      <Link
        className="btn btn-outline-secondary btn-sm template-action-btn"
        to={generatePath(PATHS.TEMPLATES_VIEW, { id: template.ID })}
      >
        <FontAwesomeIcon icon={faList} className="ms-1 fs-9" />
        &nbsp; View
      </Link>
      <Link
        className="btn btn-outline-primary btn-sm template-action-btn"
        to={generatePath(PATHS.TEMPLATES_EDIT, { id: template.ID })}
      >
        <FontAwesomeIcon icon={faPencil} className="ms-1 fs-9" />
        &nbsp; Edit
      </Link>
      <button
        className="btn btn-outline-success btn-sm template-action-btn"
        onClick={() =>
          window.confirm('Really copy template?') ? duplicateTemplate() : null
        }
      >
        <FontAwesomeIcon icon={faCopy} className="ms-1 fs-9" />
        &nbsp; Copy
      </button>
      <button
        className="btn btn-outline-danger btn-sm template-action-btn"
        onClick={() =>
          window.confirm('Really delete template?') ? deleteTemplate() : null
        }
      >
        <FontAwesomeIcon icon={faTrash} className="ms-1 fs-9" />
        &nbsp; Delete
      </button>
    </>
  );
};

export default TemplatesActions;
