import './Shipping.scss';
import React, { useState } from 'react';
import IShipmentOrderItem from '../../../../models/restful/IShipmentOrderItem';
import { api } from '../../../../api/Api';
import OrderDetailsSummaryCard from '../../order-view/OrderDetailsSummaryCard';
import IOrder from '../../../../models/restful/IOrder';

interface IShippingProps {
  order: IOrder;
  showRequired: boolean;
}

const Shipping: React.FunctionComponent<IShippingProps> = (
  props: IShippingProps
) => {
  const shoi = props.order.ShipmentOrderItem;

  const [key, setKey] = useState(0);

  if (!shoi) return null;
  const updateOI = (shoi: IShipmentOrderItem) => {
    api.update('ShipmentOrderItem', shoi).then(() => {
      setKey(key + 1);
    });
  };

  return (
    <div className="products-cont">
      <div className="order-item">
        <div className="row">
          <div className="col-8">
            <div className="row">
              <div className="col-4">
                <h4>Shipping</h4>
              </div>
              <div className="col-4">
                <h4>Price nice</h4>
              </div>
            </div>
            <h5>Recipient</h5>
            <div className="row">
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  FirstName
                </label>
                <input
                  type="text"
                  className={
                    'form-control' +
                    (props.showRequired && !shoi.FirstName ? ' is-invalid' : '')
                  }
                  placeholder="John"
                  defaultValue={shoi.FirstName}
                  required={true}
                  onChange={e => {
                    shoi.FirstName = e.currentTarget.value;
                    updateOI(shoi);
                  }}
                />
                {props.showRequired && !shoi.FirstName && (
                  <div className="invalid-feedback mb-4 ps-3">
                    Please enter your first name
                  </div>
                )}
              </div>
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  LastName
                </label>
                <input
                  type="text"
                  className={
                    'form-control' +
                    (props.showRequired && !shoi.LastName ? ' is-invalid' : '')
                  }
                  placeholder="Doe"
                  defaultValue={shoi.LastName}
                  required={true}
                  onChange={e => {
                    shoi.LastName = e.currentTarget.value;
                    updateOI(shoi);
                  }}
                />
                {props.showRequired && !shoi.LastName && (
                  <div className="invalid-feedback mb-4 ps-3">
                    Please enter your last name
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <label htmlFor="" className="col-form-label">
                  Email
                </label>
                <input
                  type="text"
                  className={
                    'form-control' +
                    (props.showRequired && !shoi.Email ? ' is-invalid' : '')
                  }
                  placeholder="John@Doe.com"
                  defaultValue={shoi.Email}
                  required={true}
                  onChange={e => {
                    shoi.Email = e.currentTarget.value;
                    updateOI(shoi);
                  }}
                />
                {props.showRequired && !shoi.FirstName && (
                  <div className="invalid-feedback mb-4 ps-3">
                    Please enter your email
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-4">
            <OrderDetailsSummaryCard order={props.order} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipping;
