import { api } from '../../api/Api';
import IDesignCollection from '../../models/collections/IDesignCollection';
import ICollectionParams from '../../models/collections/ICollectionParams';

const perPageValues = [10, 20, 50, 100];
const defaultDesignsCollection: IDesignCollection = {
  perPage: perPageValues[0],
  pageNo: 1,
  sortColumn: 'ID',
  sortDirection: '-',
  totalCount: 0,
  itemsCount: 0,
  items: []
};

const DesignsLoader = (
  collectionParams: ICollectionParams = defaultDesignsCollection
) =>
  api
    .getCollection('Design', collectionParams)
    .then(resp => resp.itemsCollection as IDesignCollection);

export default DesignsLoader;
