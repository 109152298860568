import './Designs.scss';
import React, { useState } from 'react';
import { api } from '../../../../api/Api';
import { Form } from 'react-bootstrap';
import IStore from '../../../../models/restful/IStore';
import IOrder, { IVariation } from '../../../../models/restful/IOrder';
import DesignForm from '../../../designs/DesignForm/DesignForm';
import IOrderExtended from '../../../../models/restful/IOrderExtended';
import IDesign from '../../../../models/restful/IDesign';
import Button from '../../../../components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

interface IOrderItemProps {
  order: IOrderExtended;
  showRequired: boolean;
}

const Designs: React.FunctionComponent<IOrderItemProps> = (
  props: IOrderItemProps
) => {
  const [key, setKey] = useState(0);
  const order = props.order;
  const updateOrder = (order: IOrder) => {
    api.update('Order', order);
  };

  const updateVariation = (variation: IVariation) => {
    const model = order.productsSet.models.find(
      m => m.ID === variation.ModelID
    );
    const color = order.productsSet.colors.find(
      c => c.ID === variation.ColorID
    );
    const size = order.productsSet.sizes.find(s => s.ID === variation.SizeID);
    variation.Title = `${color?.Title}, ${size?.Title}, ${model?.Title}`;
  };

  return (
    <div className="order-items-cont">
      <div className="mb-3 row">
        <div className="col-3">
          <label htmlFor="" className="col-sm-2 col-form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="My new order"
            defaultValue={order.Title}
            onChange={e => {
              order.Title = e.currentTarget.value;
              updateOrder(order);
            }}
          />
        </div>
        <div className="col-3">
          <label htmlFor="" className="col-sm-2 col-form-label">
            Store
          </label>

          <Form.Select
            className="mb-3"
            defaultValue={order.Store ? order.Store.ID : 0}
            onChange={e => {
              const id = parseInt(e.target.value);
              const st = order.storesSet.find(s => s.ID === id);
              order.StoreID = st ? st.ID : 0;
              order.Store = st
                ? st
                : ({
                    ID: 0,
                    Title: 'Choose store'
                  } as IStore);
              updateOrder(order);
              setKey(key + 1);
            }}
          >
            <option value={0} key={0}>
              Choose store
            </option>
            {order.storesSet.map(store => (
              <option value={store.ID} key={store.ID}>
                {store.Title}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="col-3">
          <label htmlFor="ExternalID" className="col-sm-2 col-form-label">
            ExternalID
          </label>
          <input
            id="ExternalID"
            type="text"
            className="form-control"
            placeholder="My External ID"
            defaultValue={order.ExternalID}
            onChange={e => {
              order.ExternalID = e.currentTarget.value;
              updateOrder(order);
            }}
          />
        </div>
        <div className="col-3">
          <label htmlFor="ExternalID" className="col-sm-2 col-form-label">
            Template
          </label>
          <Form.Select
            className="mb-3"
            defaultValue={order.Design ? order.Design.TemplateID : 0}
            onChange={e => {
              order.Design.TemplateID = parseInt(e.target.value);
              api.update('Design', order.Design).then(resp => {
                order.Design = resp.item as IDesign;
                setKey(key + 1);
              });
            }}
          >
            <option value={0} key={0}>
              Choose template
            </option>
            {order.templatesSet.map(template => (
              <option value={template.ID} key={template.ID}>
                {template.Title}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
      {props.showRequired && !order.Design.SVG && (
        <div className="alert alert-danger" role="alert">
          Design must be saved
        </div>
      )}
      {order.Design && (
        <DesignForm design={order.Design} onSave={() => setKey(key + 1)} />
      )}
      {props.order.OrderJSON.products.map(item => {
        const product = item.product;
        return (
          <div className="row" key={item.product.ID}>
            <div className="col-2">
              <h5>{product.Title}</h5>
              <Button
                variant="link"
                className="text-900 me-4 px-0 text-success"
                onClick={() => {
                  item.variations.push({
                    ModelID: 0,
                    ColorID: 0,
                    SizeID: 0,
                    Quantity: 1,
                    Title: ``
                  });
                  setKey(key + 1);
                }}
              >
                <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                Add variation
              </Button>
            </div>
            <div className="col-9">
              {item.variations.map((variation, idx) => {
                const k = `${variation.ModelID}-${variation.ColorID}-${variation.SizeID}-${idx}`;
                return (
                  <div className="row" key={k}>
                    <div className="col-2">
                      <Form.Select
                        className={
                          'mb-3' +
                          (props.showRequired && !variation.ModelID
                            ? ' is-invalid'
                            : '')
                        }
                        defaultValue={variation.ModelID}
                        onChange={e => {
                          variation.ModelID = Number(e.target.value);
                          updateVariation(variation);
                          updateOrder(order);
                          setKey(key + 1);
                        }}
                      >
                        <option value={0} key={0}>
                          Choose model
                        </option>
                        {props.order.productsSet.models.map(model => {
                          if (!product.Models.includes(model.ID)) return null;
                          return (
                            <option value={model.ID} key={model.ID}>
                              {model.Title}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                    <div className="col-2">
                      <Form.Select
                        className={
                          'mb-3' +
                          (props.showRequired && !variation.ColorID
                            ? ' is-invalid'
                            : '')
                        }
                        defaultValue={variation.ColorID}
                        onChange={e => {
                          variation.ColorID = Number(e.target.value);
                          updateVariation(variation);
                          updateOrder(order);
                          setKey(key + 1);
                        }}
                      >
                        <option value={0} key={0}>
                          Choose color
                        </option>
                        {props.order.productsSet.colors.map(color => {
                          if (!product.Colors.includes(color.ID)) return null;
                          return (
                            <option value={color.ID} key={color.ID}>
                              {color.Title}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                    <div className="col-2">
                      <Form.Select
                        className={
                          'mb-3' +
                          (props.showRequired && !variation.SizeID
                            ? ' is-invalid'
                            : '')
                        }
                        onChange={e => {
                          variation.SizeID = Number(e.target.value);
                          updateVariation(variation);
                          updateOrder(order);
                          setKey(key + 1);
                        }}
                      >
                        <option value={0} key={0}>
                          Choose size
                        </option>
                        {props.order.productsSet.sizes.map(size => {
                          if (!product.Models.includes(size.ID)) return null;
                          return (
                            <option value={size.ID} key={size.ID}>
                              {size.Title}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                    <div className="col-2">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Quantity"
                        min={1}
                        defaultValue={variation.Quantity}
                        onChange={e => {
                          variation.Quantity = Number(e.target.value);
                          updateVariation(variation);
                          updateOrder(order);
                          setKey(key + 1);
                        }}
                      />
                    </div>
                    <div className="col-2">
                      {item.variations.length > 1 && (
                        <Button
                          variant="link"
                          className="text-900 me-4 px-0 text-danger"
                          onClick={() => {
                            item.variations.splice(idx, 1);
                            setKey(key + 1);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMinus}
                            className="fs-9 me-2"
                          />
                          Remove variation
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Designs;
