import './DesignsPage.scss';
import React, { useEffect, useState } from 'react';
import IDesign from '../../models/restful/IDesign';
import { api } from '../../api/Api';
import Loader from '../../components/common/Loader/Loader';
import Button from '../../components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport,
  faPlus,
  faRefresh,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import PATHS from '../../paths';
import IDesignCollection from '../../models/collections/IDesignCollection';
import ICollectionParams from '../../models/collections/ICollectionParams';
import DesignsActions from './designs-actions/DesignsActions';

interface IDesignsProps {}

export const DesignsPageSettings = {
  isUpdateNeeded: false
};

const DesignsPage: React.FunctionComponent<IDesignsProps> = () => {
  const designDefaultCollection = useLoaderData() as IDesignCollection;
  const [loading, setLoading] = useState<boolean>(false);

  const [designsCollection, setDesignsCollection] = useState<IDesignCollection>(
    designDefaultCollection
  );

  const [selectedDesigns, setSelectedDesigns] = useState<IDesign[]>([]);

  const navigate = useNavigate();
  const updateFromServer = (designsCollectionParams?: IDesignCollection) => {
    designsCollectionParams = designsCollectionParams
      ? designsCollectionParams
      : designsCollection;
    setLoading(true);
    api.getCollection('Design', designsCollectionParams).then(resp => {
      setDesignsCollection(resp.itemsCollection as IDesignCollection);
      setLoading(false);
    });
  };

  useEffect(() => {
    updateFromServer();
  }, []);

  const deleteDesigns = (designs: IDesign[]) => {
    const collectionParams: ICollectionParams = {
      pageNo: designsCollection.pageNo,
      perPage: designsCollection.perPage
    };
    api
      .delete(
        'Design',
        designs.map(s => s.ID),
        collectionParams
      )
      .then(resp => {
        setDesignsCollection(resp.itemsCollection as IDesignCollection);
      });
  };

  return (
    <div className="designs-cont">
      {!loading && (
        <div>
          <div className="row">
            <div className="col-6">
              <h2 className="mb-4">Designs</h2>
            </div>
            <div className="col-6">
              <div className="ms-xxl-auto text-end">
                <Button
                  variant="link"
                  className="text-900 me-4 px-0"
                  onClick={() => updateFromServer(designsCollection)}
                >
                  <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
                  Reload
                </Button>
                <Button
                  variant="link"
                  className="text-900 me-4 px-0 text-danger"
                  onClick={() =>
                    confirm('Really delete selected designs?')
                      ? deleteDesigns(selectedDesigns)
                      : null
                  }
                >
                  <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
                  Delete
                </Button>
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    api.create('Design', {} as IDesign).then(resp =>
                      navigate(
                        generatePath(PATHS.TEMPLATES_EDIT, {
                          id: resp.item?.ID
                        })
                      )
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add design
                </Button>
              </div>
            </div>
          </div>
          <div className="table-responsive designs-table">
            {designsCollection.pageNo < 0 && <Loader />}
            {designsCollection.pageNo > 0 && (
              <div>
                <table className="phoenix-table fs-9 table">
                  <thead>
                    <tr>
                      <th scope="col" className="align-middle">
                        <input
                          type="checkbox"
                          onChange={e => {
                            const checked = e.currentTarget.checked;
                            setSelectedDesigns(
                              checked ? designsCollection.items : []
                            );
                          }}
                        />
                      </th>
                      <th scope="col">Created</th>
                      <th scope="col">Preview</th>
                      <th scope="col">Title</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {designsCollection.items.map((design, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="align-middle">
                            <input
                              type="checkbox"
                              data-id={design.ID}
                              checked={
                                !!selectedDesigns.find(
                                  or => or.ID === design.ID
                                )
                              }
                              onChange={e => {
                                const checked = e.currentTarget.checked;
                                if (checked)
                                  setSelectedDesigns([
                                    design,
                                    ...selectedDesigns
                                  ]);
                                else {
                                  const ors = [...selectedDesigns].filter(
                                    ord => ord.ID !== design.ID
                                  );
                                  setSelectedDesigns(ors);
                                }
                              }}
                            />
                          </td>
                          <td>{design.Created}</td>
                          <td>
                            {(design.SVGThumbPath ||
                              design.Template?.SVGThumbPath) && (
                              <img
                                src={
                                  design.SVGThumbPath ||
                                  design.Template?.SVGThumbPath
                                }
                                alt={design.Title}
                              />
                            )}
                          </td>
                          <td>{design.Title}</td>
                          <td>
                            <DesignsActions
                              design={design}
                              updateFromServer={updateFromServer}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default DesignsPage;
