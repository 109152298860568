import { TCoo, useMath } from './useMath';
import { useBBox } from './useBBox';
import {
  IControlGroup,
  IControlRect,
  IControlRotation,
  IElemGroup
} from './useFactory';
import { Svg } from '@svgdotjs/svg.js';

type TAction = 'move' | 'rotate' | 'corner' | 'width' | 'height' | null;

export const useActions = () => {
  const { distance } = useMath();
  const { bbox } = useBBox();
  let elemGroups: IElemGroup[] = [];
  let action: TAction = null;
  let startPos: TCoo = { x: 0, y: 0 };

  const registerActions = (svg: Svg, elementGroups: IElemGroup[]) => {
    elemGroups = elementGroups;
    window.addEventListener('mouseup', () => {
      if (action) stop();
    });
    svg.mousemove((e: MouseEvent) => move(getCoos(e, svg)));
  };

  const start = (actionType: TAction, startPosition: TCoo) => {
    action = actionType;
    startPos = startPosition;
    elemGroups.forEach(item => {
      if (!item.selected) return;
      const elem = item.elem;
      item.startPos = { x: Number(elem.x()), y: Number(elem.y()) };
      item.startCenterPos = { x: Number(elem.cx()), y: Number(elem.cy()) };
      item.startTr = elem.transform();
    });
  };

  const stop = () => {
    action = null;
    elemGroups.forEach(item => item.elem.removeClass('svg-moving'));
  };
  const move = (pos: TCoo) => {
    if (!action) return;
    const { dx, dy } = distance(startPos, pos);
    elemGroups.forEach(item => {
      if (!item.selected) return;
      const elem = item.elem;
      let { rotate, scaleX, scaleY } = elem.transform();
      rotate = rotate || 0;
      scaleX = scaleX || 1;
      scaleY = scaleY || 1;
      const box = elem.bbox();
      const w = Number(elem.width()) || box.w;
      const h = Number(elem.height()) || box.h;
      if (action === 'move') {
        elem.addClass('svg-moving');
        elem.move(item.startPos.x + dx, item.startPos.y + dy);
      }
      if (action === 'rotate') {
        rotate = distance(item.startCenterPos, pos).rotate;
      }
      if (action === 'width') {
        scaleX = (2 * distance(item.startCenterPos, pos).dr) / w;
      }
      if (action === 'height') {
        scaleY = (2 * distance(item.startCenterPos, pos).dr) / h;
      }
      if (action === 'corner') {
        const scale =
          Math.sqrt((w * scaleX) ** 2 + (h * scaleY) ** 2) /
          distance(item.startCenterPos, pos).dr /
          2;
        scaleX = scaleX / scale;
        scaleY = scaleY / scale;
      }

      elem.transform({ rotate, scaleX, scaleY });
      updateElemGroup(item);
    });
  };

  const updateElemGroup = (item: IElemGroup) => {
    const { elem, cs, g } = item;
    const tr = elem.transform();
    elem.transform({ scaleX: Number(tr.scaleX) + 0.2 });
    elem.transform({
      scaleX: tr.scaleX,
      scaleY: tr.scaleY,
      rotate: tr.rotate
    });
    const { cx, cy, w, h } = bbox(elem);

    const box = elem.bbox();
    const sw = box.w * Number(tr.scaleX);
    const sh = box.h * Number(tr.scaleY);

    cs.lt.center(cx - sw / 2, cy - sh / 2);
    cs.ct.center(cx, cy - sh / 2);
    cs.rt.center(cx + sw / 2, cy - sh / 2);
    cs.lc.center(cx - sw / 2, cy);
    cs.cc.center(cx, cy);
    cs.rc.center(cx + sw / 2, cy);
    cs.lb.center(cx - sw / 2, cy + sh / 2);
    cs.cb.center(cx, cy + sh / 2);
    cs.rb.center(cx + sw / 2, cy + sh / 2);
    const rotCy = cy - sh / 2 - 30;
    (cs as IControlRotation).rot?.center(cx, rotCy);
    (cs as IControlRotation).rotLine?.plot(cx, rotCy, cs.ct.cx(), cs.ct.cy());

    const svg = elem.parent(Svg) as Svg;
    (cs as IControlRect).rect?.width(sw).height(sh).center(cx, cy);
    (cs as IControlRect).bbRect?.width(w).height(h).center(cx, cy).addTo(svg);

    g.cx(cx)
      .cy(cy - 15)
      .transform({
        rotate: tr.rotate,
        originX: cx,
        originY: cy
      })
      .front();
  };

  const bindCSEvents = (item: IElemGroup) => {
    const elem = item.elem;
    const cs = item.cs as IControlGroup;
    const svg = elem.parent(Svg) as Svg;
    elem.mousedown((e: MouseEvent) => {
      if (svg && item && item.selected) start('move', getCoos(e, svg));
    });
    elem.mouseup((e: MouseEvent) => {
      if (item?.selected) {
        e.preventDefault();
        e.stopPropagation();
        stop();
      }
    });
    cs.lt.mousedown((e: MouseEvent) => start('corner', getCoos(e, svg)));
    cs.rt.mousedown((e: MouseEvent) => start('corner', getCoos(e, svg)));
    cs.rb.mousedown((e: MouseEvent) => start('corner', getCoos(e, svg)));
    cs.lb.mousedown((e: MouseEvent) => start('corner', getCoos(e, svg)));
    cs.ct.mousedown((e: MouseEvent) => start('height', getCoos(e, svg)));
    cs.cb.mousedown((e: MouseEvent) => start('height', getCoos(e, svg)));
    cs.lc.mousedown((e: MouseEvent) => start('width', getCoos(e, svg)));
    cs.rc.mousedown((e: MouseEvent) => start('width', getCoos(e, svg)));
    cs.rot.mousedown((e: MouseEvent) => start('rotate', getCoos(e, svg)));
  };

  const getCoos = (e: MouseEvent, svg: Svg) => {
    const root = document.getElementById(svg.id())?.parentElement;
    const rect = root?.getBoundingClientRect();
    return { x: e.clientX - Number(rect?.x), y: e.clientY - Number(rect?.y) };
  };

  const getAction = () => action;

  return {
    getAction,
    registerActions,
    start,
    stop,
    move,
    bindCSEvents,
    updateElemGroup
  };
};
