import './DesignView.scss';
import React, { useState } from 'react';
import IDesign from '../../../models/restful/IDesign';
import DesignPreview from './DesignPreview/DesignPreview';
import { generatePath, Link, useParams } from 'react-router-dom';
import { api } from '../../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faRefresh } from '@fortawesome/free-solid-svg-icons';
import PATHS from '../../../paths';

interface IDesignViewProps {
  design?: IDesign;
}

const DesignView: React.FunctionComponent<IDesignViewProps> = (
  props: IDesignViewProps
) => {
  const [design, setDesign] = useState<IDesign>(
    props.design || ({} as IDesign)
  );
  const { id } = useParams();
  const updateFromServer = () => {
    api.read('Design', '' + id).then(resp => {
      if (resp.item) {
        setDesign(resp.item as IDesign);
      }
    });
  };
  if (!design.ID) {
    updateFromServer();
    return null;
  }

  return (
    <div className="design-view-cont">
      <div className="row pt-3 pb-2 mb-3 bdesign-bottom">
        <div className="col-6">
          <h3 className="h2 pt-3">Design #{design.ID}</h3>
        </div>
        <div className="col-4"></div>
        <div className="col-2">
          <Link
            to="#"
            className="text-900 me-4 px-0"
            onClick={() => updateFromServer()}
          >
            <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
            Reload
          </Link>
          <Link
            to={generatePath(PATHS.TEMPLATES)}
            className="text-900 me-4 px-0"
          >
            <FontAwesomeIcon icon={faPalette} className="fs-9 me-2" />
            All designs
          </Link>
        </div>
      </div>
      <DesignPreview design={design} />
    </div>
  );
};

export default DesignView;
