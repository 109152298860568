import { Element, Svg } from '@svgdotjs/svg.js';
import { useControlGroup } from './useControlGroup';

export const SELECTED_CLASS = 'svg-selected';
export const TOUCHED_CLASS = 'svg-touched';

export const useSelected = (svg?: Svg) => {
  const { register, bindControls, unbindElem, removeElem, updateControlGroup } =
    useControlGroup();
  let svgElement = svg;
  let selectedElement: Element | null = null;
  // let selectedPool: Element[] = [];

  const setSvg = (svg: Svg) => {
    svgElement = svg;
    register(svgElement);
  };
  const updateGroup = () => {
    if (selectedElement) updateControlGroup(selectedElement);
  };
  const deselect = () => {
    if (selectedElement) {
      selectedElement.removeClass('svg-selected');
      unbindElem(selectedElement);
      selectedElement = null;
    }
  };
  const selectElement = (elem: Element) => {
    selectedElement = elem;
    bindControls(elem);
    selectedElement.addClass('svg-selected');
  };
  const removeSelected = () => {
    if (selectedElement) {
      removeElem(selectedElement);
      deselect();
      selectedElement.remove();
    }
  };

  return {
    setSvg,
    selectElement,
    deselect,
    removeSelected,
    updateGroup
  };
};
