import './TemplateView.scss';
import React, { useState } from 'react';
import ITemplate from '../../../models/restful/ITemplate';
import TemplatePreview from './TemplatePreview/TemplatePreview';
import { generatePath, Link, useParams } from 'react-router-dom';
import { api } from '../../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faRefresh } from '@fortawesome/free-solid-svg-icons';
import PATHS from '../../../paths';

interface ITemplateViewProps {
  template?: ITemplate;
}

const TemplateView: React.FunctionComponent<ITemplateViewProps> = (
  props: ITemplateViewProps
) => {
  const [template, setTemplate] = useState<ITemplate>(
    props.template || ({} as ITemplate)
  );
  const { id } = useParams();
  const updateFromServer = () => {
    api.read('Template', '' + id).then(resp => {
      if (resp.item) {
        setTemplate(resp.item as ITemplate);
      }
    });
  };
  if (!template.ID) {
    updateFromServer();
    return null;
  }

  return (
    <div className="template-view-cont">
      <div className="row pt-3 pb-2 mb-3 btemplate-bottom">
        <div className="col-6">
          <h3 className="h2 pt-3">Template #{template.ID}</h3>
        </div>
        <div className="col-4"></div>
        <div className="col-2">
          <Link
            to="#"
            className="text-900 me-4 px-0"
            onClick={() => updateFromServer()}
          >
            <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
            Reload
          </Link>
          <Link
            to={generatePath(PATHS.TEMPLATES)}
            className="text-900 me-4 px-0"
          >
            <FontAwesomeIcon icon={faPalette} className="fs-9 me-2" />
            All templates
          </Link>
        </div>
      </div>
      <TemplatePreview template={template} />
    </div>
  );
};

export default TemplateView;
