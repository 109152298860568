import axios from 'axios';
import IDataObject from '../models/restful/IDataObject';
import IResponse from '../models/responces/IResponse';
import ICollectionParams from '../models/collections/ICollectionParams';
import { ICollectionResponse } from '../models/responces/ICollectionResponse';
import IUserResponse from '../models/responces/IUserResponse';
import { useUser } from '../hooks/useUser';

const url = (
  dataObjectName: string,
  id?: number | string,
  handler = 'restful',
  field = 'ID'
) => {
  if (field === 'ID')
    return `${apiUrl()}/${handler}/${dataObjectName}` + (id ? `/${id}` : ``);
  return `${apiUrl()}/${handler}/${dataObjectName}?${field}=${id}`;
};

const collectionParamsToString = (collectionParams: ICollectionParams) => {
  const u = new URLSearchParams();
  for (const [key, value] of Object.entries(collectionParams)) {
    u.append(key, '' + value);
  }
  return u.toString();
};

export const api = {
  host: process.env.REACT_APP_API_URL,
  getToken: () => {
    const { getUser } = useUser();
    return getUser()?.RestfulToken;
  },
  auth: (email: string, pass: string) =>
    axios({
      method: 'post',
      url: apiUrl() + '/auth',
      data: { email: email, pass: pass },
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IUserResponse>,
  create: (dataObjectName: string, dataObject: IDataObject) =>
    axios({
      method: 'post',
      headers: { Authorization: api.getToken() },
      url: url(dataObjectName),
      data: dataObject,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>,

  read: (dataObjectName: string, id: number | string, field = 'ID') =>
    axios({
      method: 'get',
      headers: { Authorization: api.getToken() },
      url: url(dataObjectName, id, 'restful', field),
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>,

  update: (dataObjectName: string, dataObject: IDataObject) =>
    axios({
      method: 'put',
      headers: { Authorization: api.getToken() },
      url: url(dataObjectName, dataObject.ID),
      data: dataObject,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>,

  patch: (
    dataObjectName: string,
    action: string,
    dataObject: IDataObject,
    options?: object
  ) =>
    axios({
      method: 'patch',
      headers: { Authorization: api.getToken() },
      url: url(dataObjectName, dataObject.ID) + '/' + action,
      data: { ...dataObject, _options: options },
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>,

  delete: (
    dataObjectName: string,
    ids: number[],
    collectionParams?: ICollectionParams
  ) =>
    axios({
      method: 'delete',
      headers: { Authorization: api.getToken() },
      url: url(dataObjectName, ids.join(',')),
      data: collectionParams ? collectionParams : {},
      responseType: 'json'
    }).then(resp => resp.data) as Promise<IResponse>,

  getCollection: (
    dataObjectName: string,
    collectionParams: ICollectionParams
  ) =>
    axios({
      method: 'get',
      headers: { Authorization: api.getToken() },
      url: `${url(dataObjectName)}?${collectionParamsToString(
        collectionParams
      )}`,
      responseType: 'json'
    }).then(resp => resp.data) as Promise<ICollectionResponse>
};

export const apiUrl = () => `${process.env.REACT_APP_API_URL}/api`;
