import { Element, Svg } from '@svgdotjs/svg.js';
import { useActions } from './useActions';
import { IControlRect, IElemGroup, useFactory } from './useFactory';
import { useSVGByRoot } from './useSVGByRoot';

export type TControlType = 'bound' | 'group';

export const useControlGroup = () => {
  const { getAction, registerActions, bindCSEvents, updateElemGroup } =
    useActions();
  const { createSVGGroupBound, createSVGGroupControls, createItem } =
    useFactory();

  let heep: IElemGroup[] = [];

  const register = (svg: Svg) => {
    registerActions(svg, heep);
    svg.mouseup(() => {
      if (!getAction()) useSVGByRoot(svg.parent()?.id() + '').clearSelection();
    });
  };

  const bindElem = (elem: Element, type?: TControlType) => {
    let item = heep.find(e => e.elem === elem);
    if (!item) {
      const o =
        type === 'bound' ? createSVGGroupBound() : createSVGGroupControls();
      item = createItem(elem, o.g, o.cs);
      heep.push(item);
    }
    item.selected = true;
    item.startPos = { x: Number(elem.x()), y: Number(elem.y()) };
    item.startCenterPos = { x: Number(elem.cx()), y: Number(elem.cy()) };
    item.startTr = elem.transform();
    const svg = elem.parent(Svg) as Svg;
    if (svg) item.g.addTo(svg);
    updateControlGroup(elem);
    return item;
  };

  const bindInSelection = (elem: Element) => bindElem(elem, 'bound');
  const bindControls = (elem: Element) => {
    const item = heep.find(e => e.elem === elem);
    if (item) bindElem(elem);
    else bindEvents(bindElem(elem));
  };

  const unbindElem = (elem: Element) => {
    const item = heep.find(e => e.elem === elem);
    if (item) {
      item.g.remove();
      (item.cs as IControlRect).bbRect?.remove();
      item.selected = false;
    }
  };

  const removeElem = (elem: Element) => {
    heep = heep.filter(it => elem !== it.elem);
  };

  const bindEvents = (item: IElemGroup) => bindCSEvents(item);

  const updateControlGroup = (elem: Element) => {
    const item = heep.find(e => e.elem === elem);
    if (!item) return;
    updateElemGroup(item);
  };

  return {
    register,
    bindInSelection,
    bindControls,
    unbindElem,
    removeElem,
    updateControlGroup
  };
};
