import './TemplatesPage.scss';
import React, { useEffect, useState } from 'react';
import ITemplate from '../../models/restful/ITemplate';
import { api } from '../../api/Api';
import Loader from '../../components/common/Loader/Loader';
import Button from '../../components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport,
  faPlus,
  faRefresh,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import PATHS from '../../paths';
import ITemplateCollection from '../../models/collections/ITemplateCollection';
import ICollectionParams from '../../models/collections/ICollectionParams';
import TemplatesActions from './templates-actions/TemplatesActions';

interface ITemplatesProps {}

export const TemplatesPageSettings = {
  isUpdateNeeded: false
};

const TemplatesPage: React.FunctionComponent<ITemplatesProps> = () => {
  const templateDefaultCollection = useLoaderData() as ITemplateCollection;
  const [loading, setLoading] = useState<boolean>(false);

  const [templatesCollection, setTemplatesCollection] =
    useState<ITemplateCollection>(templateDefaultCollection);

  const [selectedTemplates, setSelectedTemplates] = useState<ITemplate[]>([]);

  const navigate = useNavigate();
  const updateFromServer = (
    templatesCollectionParams?: ITemplateCollection
  ) => {
    templatesCollectionParams = templatesCollectionParams
      ? templatesCollectionParams
      : templatesCollection;
    setLoading(true);
    api.getCollection('Template', templatesCollectionParams).then(resp => {
      setTemplatesCollection(resp.itemsCollection as ITemplateCollection);
      setLoading(false);
    });
  };

  useEffect(() => {
    updateFromServer();
  }, []);

  const deleteTemplates = (templates: ITemplate[]) => {
    const collectionParams: ICollectionParams = {
      pageNo: templatesCollection.pageNo,
      perPage: templatesCollection.perPage
    };
    api
      .delete(
        'Template',
        templates.map(s => s.ID),
        collectionParams
      )
      .then(resp => {
        setTemplatesCollection(resp.itemsCollection as ITemplateCollection);
      });
  };

  return (
    <div className="templates-cont">
      {!loading && (
        <div>
          <div className="row">
            <div className="col-6">
              <h2 className="mb-4">Templates</h2>
            </div>
            <div className="col-6">
              <div className="ms-xxl-auto text-end">
                <Button
                  variant="link"
                  className="text-900 me-4 px-0"
                  onClick={() => updateFromServer(templatesCollection)}
                >
                  <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
                  Reload
                </Button>
                <Button
                  variant="link"
                  className="text-900 me-4 px-0 text-danger"
                  onClick={() =>
                    confirm('Really delete selected templates?')
                      ? deleteTemplates(selectedTemplates)
                      : null
                  }
                >
                  <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
                  Delete
                </Button>
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    api.create('Template', {} as ITemplate).then(resp =>
                      navigate(
                        generatePath(PATHS.TEMPLATES_EDIT, {
                          id: resp.item?.ID
                        })
                      )
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add template
                </Button>
              </div>
            </div>
          </div>
          <div className="table-responsive templates-table">
            {templatesCollection.pageNo < 0 && <Loader />}
            {templatesCollection.pageNo > 0 && (
              <div>
                <table className="phoenix-table fs-9 table">
                  <thead>
                    <tr>
                      <th scope="col" className="align-middle">
                        <input
                          type="checkbox"
                          onChange={e => {
                            const checked = e.currentTarget.checked;
                            setSelectedTemplates(
                              checked ? templatesCollection.items : []
                            );
                          }}
                        />
                      </th>
                      <th scope="col">Created</th>
                      <th scope="col">Preview</th>
                      <th scope="col">Title</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {templatesCollection.items.map((template, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="align-middle">
                            <input
                              type="checkbox"
                              data-id={template.ID}
                              checked={
                                !!selectedTemplates.find(
                                  or => or.ID === template.ID
                                )
                              }
                              onChange={e => {
                                const checked = e.currentTarget.checked;
                                if (checked)
                                  setSelectedTemplates([
                                    template,
                                    ...selectedTemplates
                                  ]);
                                else {
                                  const ors = [...selectedTemplates].filter(
                                    ord => ord.ID !== template.ID
                                  );
                                  setSelectedTemplates(ors);
                                }
                              }}
                            />
                          </td>
                          <td>{template.Created}</td>
                          <td>
                            {template.SVGThumbPath && (
                              <img
                                src={template.SVGThumbPath}
                                alt={template.Title}
                              />
                            )}
                          </td>
                          <td>{template.Title}</td>
                          <td>
                            <TemplatesActions
                              template={template}
                              updateFromServer={updateFromServer}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default TemplatesPage;
