import { Element } from '@svgdotjs/svg.js';
import { useBBox } from './useBBox';

export type TPosition =
  | 'LT'
  | 'CT'
  | 'RT'
  | 'LC'
  | 'CC'
  | 'RC'
  | 'LB'
  | 'CB'
  | 'RB';

export const useTransform = () => {
  const applyAlign = (child: Element, parent: Element, position: TPosition) => {
    const { bbox, coo } = useBBox();
    const ds = bbox(child);
    const pds = bbox(parent);
    const c = { ...coo };
    const h = position.charAt(0);
    const v = position.charAt(1);

    if (h === 'L') c.x = pds.cx - pds.w / 2 + ds.w / 2;
    if (h === 'C') c.x = pds.cx;
    if (h === 'R') c.x = pds.cx + pds.w / 2 - ds.w / 2;
    if (v === 'T') c.y = pds.cy - pds.h / 2 + ds.h / 2;
    if (v === 'C') c.y = pds.cy;
    if (v === 'B') c.y = pds.cy + pds.h / 2 - ds.h / 2;
    const tr = child.transform();
    child
      .center(c.x, c.y)
      .transform({ scaleX: tr.scaleX, scaleY: tr.scaleY, rotate: tr.rotate });
  };
  const applyTransformRotation = (child: Element, alpha: number) => {
    const tr = child.transform();
    child.transform({
      scaleX: tr.scaleX,
      scaleY: tr.scaleY,
      rotate: alpha
    });
  };

  const cleanTransformation = (element: Element) => {
    element.transform({
      scaleX: 1,
      scaleY: 1,
      rotate: 0
    });
  };

  return {
    applyAlign,
    applyTransformRotation,
    cleanTransformation
  };
};
