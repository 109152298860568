import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../api/Api';
import { useUser } from '../../../hooks/useUser';
import { useState } from 'react';

const SignInForm = () => {
  const { setUser } = useUser();
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">Sign In</h3>
        <p className="text-700">Get access to your account</p>
      </div>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="email">Email address</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="email"
            type="email"
            className="form-icon-input"
            placeholder="name@example.com"
          />
          <FontAwesomeIcon icon={faUser} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Password</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder="Password"
          />
          <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Button
        variant="primary"
        className="w-100 mb-3"
        onClick={() => {
          const emailInput = document.getElementById(
            'email'
          ) as HTMLInputElement;
          const passInput = document.getElementById(
            'password'
          ) as HTMLInputElement;
          if (emailInput.value && passInput.value) {
            api.auth(emailInput.value, passInput.value).then(userResponse => {
              if (userResponse.success) {
                setUser(userResponse.item);
                navigate('/');
              } else setError('' + userResponse.error);
            });
          }
        }}
      >
        Sign In
      </Button>
    </>
  );
};

export default SignInForm;
