import './Products.scss';
import React, { useEffect, useState } from 'react';
import Product from './Product/Product';
import FormCollapse from '../../../../components/common/FormCollapse';
import IMerchProduct from '../../../../models/restful/IMerchProduct';
import { IMerchSubCategory } from '../../../../models/restful/IMerchCategory';
import { Form } from 'react-bootstrap';
import IOrderExtended from '../../../../models/restful/IOrderExtended';
import { api } from '../../../../api/Api';

interface IProductsProps {
  order: IOrderExtended;
  showRequired: boolean;
}

const Products: React.FunctionComponent<IProductsProps> = (
  props: IProductsProps
) => {
  const [selectedCats, setSelectedCats] = useState<IMerchSubCategory[]>([]);
  const [key, setKey] = useState(0);
  const categories = props.order.productsSet.categories;
  const products = props.order.productsSet.products;
  const orderJSON = props.order.OrderJSON;

  const pids: number[] = [];
  selectedCats.map(cat => cat.Products.forEach(pid => pids.push(pid)));
  const prods: IMerchProduct[] = products.filter(p => pids.includes(p.ID));

  const selectAllCats = (b: boolean) => {
    const selCats: IMerchSubCategory[] = [];
    if (b) {
      categories.forEach(cat =>
        cat.SubCategories.forEach(cat1 => selCats.push(cat1))
      );
    }
    setSelectedCats(selCats);
  };

  const jsonChanged = () => {
    api.update('Order', props.order);
    setKey(key + 1);
  };

  useEffect(() => {
    selectAllCats(true);
  }, []);

  return (
    <div className="products-cont">
      {props.showRequired && !orderJSON.products.length && (
        <div className="alert alert-danger" role="alert">
          Select at least one product
        </div>
      )}
      {categories.length && (
        <div className="row">
          <div className="col-3">
            <Form.Check
              type="checkbox"
              id="all_cats"
              className="mb-0 d-flex align-items-center gap-2"
              key="all_cats"
            >
              <Form.Check.Input
                type="checkbox"
                className="mt-0"
                defaultChecked={true}
                onChange={e => selectAllCats(e.target.checked)}
              />
              <Form.Check.Label className="d-block lh-sm fs-8 text-900 fw-normal mb-0">
                All products
              </Form.Check.Label>
            </Form.Check>
            {categories.length > 0 &&
              categories.map(cat1 => {
                return (
                  <FormCollapse title={cat1.Title} key={cat1.ID}>
                    {cat1.SubCategories.map(cat2 => {
                      return (
                        <Form.Check
                          type="checkbox"
                          id={`cat_${cat2.ID}`}
                          className="mb-0 d-flex align-items-center gap-2"
                          key={cat2.ID}
                        >
                          <Form.Check.Input
                            type="checkbox"
                            className="mt-0"
                            checked={!!selectedCats.find(c => c.ID === cat2.ID)}
                            onChange={e => {
                              if (e.target.checked)
                                setSelectedCats([...selectedCats, cat2]);
                              else
                                setSelectedCats(
                                  selectedCats.filter(ii => ii.ID !== cat2.ID)
                                );
                            }}
                          />
                          <Form.Check.Label className="d-block lh-sm fs-8 text-900 fw-normal mb-0">
                            {cat2.Title}
                          </Form.Check.Label>
                        </Form.Check>
                      );
                    })}
                  </FormCollapse>
                );
              })}
          </div>
          <div className="col-9">
            {orderJSON.products.length > 0 && (
              <div className="row">
                {orderJSON.products.map(item => {
                  const product = item.product;
                  return (
                    <Product
                      order={props.order}
                      product={product}
                      key={product.ID}
                      onChange={jsonChanged}
                    />
                  );
                })}
              </div>
            )}
            {!orderJSON.products.length && (
              <div className="alert alert-outline-secondary" role="alert">
                No selected products
              </div>
            )}
            <hr />
            {prods.length > 0 && (
              <div className="row">
                {prods.map(product => {
                  const checked = orderJSON.products.find(
                    item => item.product.ID === product.ID
                  );
                  if (checked) return null;
                  return (
                    <Product
                      order={props.order}
                      product={product}
                      key={product.ID}
                      onChange={jsonChanged}
                    />
                  );
                })}
              </div>
            )}
            {prods.length === 0 && (
              <div className="alert alert-outline-secondary" role="alert">
                Select a category
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
