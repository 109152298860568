import './Confirm.scss';
import React from 'react';
import IOrder from '../../../../models/restful/IOrder';
import OrderDetails from '../../order-view/OrderDetails';

interface IConfirmProps {
  order: IOrder;
}

const Confirm: React.FunctionComponent<IConfirmProps> = (
  props: IConfirmProps
) => {
  return (
    <div className="order-form-confirm-cont">
      <OrderDetails order={props.order} hideTitle={true} />
    </div>
  );
};

export default Confirm;
