import './OrderPreview.scss';
import React from 'react';
import IOrder from '../../../../models/restful/IOrder';

interface IOrderViewProps {
  order: IOrder;
}

const OrderPreview: React.FunctionComponent<IOrderViewProps> = (
  props: IOrderViewProps
) => {
  const order = props.order;

  return (
    <div className="order-preview-cont">
      <h4>Title:</h4>
      <p>{order.Title}</p>
      {order.Description && (
        <>
          <h5>Description:</h5>
          <p>{order.Description}</p>
        </>
      )}
    </div>
  );
};

export default OrderPreview;
