import './FontPreview.scss';
import React from 'react';
import IFont from '../../../../models/restful/IFont';

interface IFontViewProps {
  Font: IFont;
}

const FontPreview: React.FunctionComponent<IFontViewProps> = (
  props: IFontViewProps
) => {
  const Font = props.Font;

  return (
    <div className="Font-preview-cont">
      <h4>Title:</h4>
      <p>{Font.Title}</p>
      <h5>Description:</h5>
      <p>{Font.Description}</p>
    </div>
  );
};

export default FontPreview;
