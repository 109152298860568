import './DesignBlock.scss';
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faArrowDown,
  faArrowsAltH,
  faArrowsAltV,
  faArrowUp,
  faImagePortrait,
  faTrash,
  faTrashArrowUp
} from '@fortawesome/free-solid-svg-icons';
import IFile from '../../models/restful/IFile';
import { useSVGByRoot } from './hooks/useSVGByRoot';
import { Dropdown, Form } from 'react-bootstrap';
import { Element, Text } from '@svgdotjs/svg.js';
import IFontCollection from '../../models/collections/IFontCollection';
import ITagCollection from '../../models/collections/ITagCollection';
import ITag from '../../models/restful/ITag';

interface IDesignViewProps {
  contID: string;
  saveImages(files: FileList, callback: (files: IFile[]) => void): void;
  fontsCollection: IFontCollection;
  tagsCollection: ITagCollection;
}

let tag: ITag | null = null;

const DesignBlock: React.FunctionComponent<IDesignViewProps> = props => {
  const {
    dropImage,
    dropText,
    applyTextProp,
    fitSelectedToWidth,
    fitSelectedToHeight,
    applyTransform,
    applyRotation,
    applyTag,
    removeSelection,
    cleanSelected,
    addOnselectEventListener,
    addOndeselectEventListener,
    selectedToUp,
    selectedToDown
  } = useSVGByRoot(props.contID);
  let font = props.fontsCollection.items.length
    ? props.fontsCollection.items[0]
    : null;

  const onSelectElem = (elem: Element) => {
    const t = elem as Text;
    const tt = props.tagsCollection.items.find(
      it => it.ID === Number(elem.data('tagid'))
    );
    const tagDropdown = document.getElementById('tag-dropdown');
    if (tagDropdown) tagDropdown.innerText = tt ? tt.Title : 'None';
    if (!t.text) return;
    const input = document.getElementById('svg-text-input') as HTMLInputElement;
    input.value = t.text();
    const inputColor = document.getElementById(
      'svg-color-input'
    ) as HTMLInputElement;
    inputColor.value = t.fill();
  };

  const onDeselect = () => {
    const tagDropdown = document.getElementById('tag-dropdown');
    if (tagDropdown) tagDropdown.innerText = 'None';
  };

  useEffect(() => {
    window.setTimeout(() => {
      addOnselectEventListener(onSelectElem);
      addOndeselectEventListener(onDeselect);
    }, 50);
  }, []);

  return (
    <div className="design-block-cont">
      <div className="row">
        <div className="col-8">
          <div
            id={props.contID}
            className="md-transp md-transp-8"
            onDragOver={e => {
              e.stopPropagation();
              e.preventDefault();
              e.currentTarget.classList.add('dragover');
            }}
            onDragLeave={e => {
              e.stopPropagation();
              e.preventDefault();
              e.currentTarget.classList.remove('dragover');
            }}
            onDragEnd={e => {
              e.stopPropagation();
              e.preventDefault();
              e.currentTarget.classList.remove('dragover');
            }}
            onDrop={e => {
              e.preventDefault();
              e.stopPropagation();
              e.currentTarget.classList.remove('dragover');
              const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
              for (const f of e.dataTransfer.files) {
                if (!allowedExtensions.exec(f.name)) {
                  alert(
                    'Please upload file having extensions .jpeg/.jpg/.png only.'
                  );
                  return false;
                }
              }
              props.saveImages(e.dataTransfer.files, files => {
                files.forEach(file => {
                  dropImage(file.PublicUrl);
                });
              });
            }}
          ></div>
          <hr className="mt-3" />
          <div className="input-group mb-3">
            <Dropdown>
              <Dropdown.Toggle
                variant="primary"
                id="font-dropdown"
                style={{
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                  fontFamily: font?.FontFamily
                }}
              >
                {font && font.FontFamily}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {props.fontsCollection.items.map(f => {
                  return (
                    <Dropdown.Item
                      key={f.ID}
                      href="#"
                      onClick={() => {
                        font = f;
                        const fontDropdown =
                          document.getElementById('font-dropdown');
                        if (fontDropdown) {
                          fontDropdown.innerText = f.FontFamily;
                          fontDropdown.style.fontFamily = f.FontFamily;
                        }
                        applyTextProp('font-family', font.FontFamily);
                        applyTextProp('data-font-family', `${font.ID}`);
                      }}
                      style={{ fontFamily: f.FontFamily }}
                    >
                      {f.FontFamily}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Form.Control
              type="color"
              defaultValue="#000"
              title="Choose your color"
              style={{ maxWidth: '60px' }}
              id="svg-color-input"
              onChange={e => {
                applyTextProp('color', e.target.value);
              }}
            />
            <input
              type="text"
              className="form-control"
              placeholder="type text"
              id="svg-text-input"
              onChange={e => {
                applyTextProp('text', e.target.value);
              }}
            />
            <span
              className="btn btn-primary"
              onClick={() => {
                const inputText = document.getElementById(
                  'svg-text-input'
                ) as HTMLInputElement;
                const inputColor = document.getElementById(
                  'svg-color-input'
                ) as HTMLInputElement;
                dropText(
                  inputText?.value,
                  inputColor?.value,
                  `${font?.FontFamily}`
                );
              }}
            >
              add
            </span>
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <button type="button" className="btn btn-primary btn-upload">
              <input
                type="file"
                accept="image/jpeg,image/png"
                onChange={e => {
                  if (e.target.files) {
                    props.saveImages(e.target.files, files => {
                      files.forEach(file => {
                        dropImage(file.PublicUrl);
                      });
                    });
                  }
                }}
              />
              <FontAwesomeIcon icon={faImagePortrait} className="fs-9 me-2" />
              Add images
            </button>
          </div>
          <hr />
          <div className="mb-3">
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => cleanSelected()}
            >
              <FontAwesomeIcon icon={faTrashArrowUp} className="fs-9 me-2" />
              Clean
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => removeSelection()}
            >
              <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
              Remove
            </button>
          </div>
          <hr />
          <div
            className="btn-group-vertical"
            role="group"
            aria-label="Vertical button group"
          >
            <div className="btn-group btn-group-sm" role="group" aria-label="">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyTransform('LT')}
              >
                <FontAwesomeIcon
                  icon={faAlignLeft}
                  style={{ transform: 'rotate(90deg)' }}
                />
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyTransform('CT')}
              >
                <FontAwesomeIcon
                  icon={faAlignCenter}
                  style={{ transform: 'rotate(180deg)' }}
                />
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyTransform('RT')}
              >
                <FontAwesomeIcon
                  icon={faAlignRight}
                  style={{ transform: 'rotate(-90deg)' }}
                />
              </button>
            </div>
            <div className="btn-group btn-group-sm" role="group" aria-label="">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyTransform('LC')}
              >
                <FontAwesomeIcon
                  icon={faAlignCenter}
                  style={{ transform: 'rotate(90deg)' }}
                />
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyTransform('CC')}
              >
                <FontAwesomeIcon icon={faAlignCenter} />
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyTransform('RC')}
              >
                <FontAwesomeIcon
                  icon={faAlignCenter}
                  style={{ transform: 'rotate(-90deg)' }}
                />
              </button>
            </div>
            <div className="btn-group btn-group-sm" role="group" aria-label="">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyTransform('LB')}
              >
                <FontAwesomeIcon
                  icon={faAlignRight}
                  style={{ transform: 'rotate(90deg)' }}
                />
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyTransform('CB')}
              >
                <FontAwesomeIcon icon={faAlignCenter} />
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyTransform('RB')}
              >
                <FontAwesomeIcon
                  icon={faAlignLeft}
                  style={{ transform: 'rotate(-90deg)' }}
                />
              </button>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-4">
              <div
                className="btn-group btn-group-sm"
                role="group"
                aria-label=""
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => fitSelectedToWidth()}
                >
                  <FontAwesomeIcon icon={faArrowsAltH} />
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => fitSelectedToHeight()}
                >
                  <FontAwesomeIcon icon={faArrowsAltV} />
                </button>
              </div>
            </div>
            <div className="col-4">
              <div
                className="btn-group btn-group-sm"
                role="group"
                aria-label=""
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => selectedToUp()}
                >
                  <FontAwesomeIcon icon={faArrowUp} />
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => selectedToDown()}
                >
                  <FontAwesomeIcon icon={faArrowDown} />
                </button>
              </div>
            </div>
          </div>

          <hr />
          <div
            className="btn-group-vertical"
            role="group"
            aria-label="Vertical button group"
          >
            <div className="btn-group btn-group-sm" role="group" aria-label="">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyRotation(0)}
              >
                0
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyRotation(90)}
              >
                90
              </button>
            </div>
            <div className="btn-group btn-group-sm" role="group" aria-label="">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyRotation(180)}
              >
                180
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => applyRotation(270)}
              >
                270
              </button>
            </div>
          </div>

          <hr />
          <div className="input-group mb-3">
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="tag-dropdown">
                {tag && tag.Title}
                {!tag && <span>None</span>}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  key={0}
                  href="#"
                  onClick={() => {
                    tag = null;
                    const tagDropdown = document.getElementById('tag-dropdown');
                    if (tagDropdown) tagDropdown.innerText = 'None';
                    applyTag(0);
                  }}
                >
                  None
                </Dropdown.Item>

                {props.tagsCollection.items.map(t => {
                  return (
                    <Dropdown.Item
                      key={t.ID}
                      href="#"
                      onClick={() => {
                        tag = t;
                        const tagDropdown =
                          document.getElementById('tag-dropdown');
                        if (tagDropdown)
                          tagDropdown.innerText = tag?.Title + '';
                        applyTag(t.ID);
                      }}
                    >
                      {t.Title}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignBlock;
