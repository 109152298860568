import { Element } from '@svgdotjs/svg.js';
import { useBBox } from './useBBox';

export const useFitting = () => {
  const fitWidth = (child: Element, parent: Element) => {
    const { bbox } = useBBox();
    const ds = bbox(child);
    const pds = bbox(parent);
    const k = pds.w / ds.w;
    const tr = child.transform();
    child.center(pds.cx, pds.cy);
    child.transform({
      scaleX: Number(tr.scaleX) * k,
      scaleY: Number(tr.scaleY) * k,
      rotate: tr.rotate
    });
  };

  const fitHeight = (child: Element, parent: Element) => {
    const { bbox } = useBBox();
    const ds = bbox(child);
    const pds = bbox(parent);
    const k = pds.h / ds.h;
    const tr = child.transform();
    child.center(pds.cx, pds.cy);
    child.transform({
      scaleX: Number(tr.scaleX) * k,
      scaleY: Number(tr.scaleY) * k,
      rotate: tr.rotate
    });
  };

  return {
    fitWidth,
    fitHeight
  };
};
