import Home from '../pages/home/Home';
import PATHS from '../paths';
import TemplatesPage from '../pages/templates/TemplatesPage';
import TemplatesLoader from '../loaders/TemplatesLoader/TemplatesLoader';
import TemplateView from '../pages/templates/TemplateView/TemplateView';
import TemplateForm from '../pages/templates/TemplateForm/TemplateForm';
import DesignsPage from '../pages/designs/DesignsPage';
import DesignView from '../pages/designs/DesignView/DesignView';
import DesignForm from '../pages/designs/DesignForm/DesignForm';
import DesignsLoader from '../loaders/DesignsLoader/DesignsLoader';
import FontsPage from '../pages/fonts/FontsPage';
import FontsLoader from '../loaders/FontsLoader/FontsLoader';
import FontView from '../pages/fonts/FontView/FontView';
import FontForm from '../pages/fonts/FontForm/FontForm';
import OrdersPage from '../pages/orders/OrdersPage';
import OrderView from '../pages/orders/OrderView/OrderView';
import OrderForm from '../pages/orders/order-edit/OrderForm';
import OrdersLoader from '../loaders/OrdersLoader/OrdersLoader';
import StoresPage from '../pages/stores/StoresPage';
import StoreView from '../pages/stores/StoreView/StoreView';
import StoreForm from '../pages/stores/StoreForm/StoreForm';
import StoresLoader from '../loaders/StoresLoader/StoresLoader';
import StoreConnectorPage from '../pages/store-connector/StoreConnectorPage';
import StoreConnectorLoader from '../loaders/StoreConnectorLoader/StoreConnectorLoader';

export const MAIN_LAYOUT_ROUTES = [
  {
    index: true,
    element: <Home />
  },
  {
    path: PATHS.TEMPLATES,
    element: <TemplatesPage />,
    loader: () => TemplatesLoader()
  },
  {
    path: PATHS.TEMPLATES_VIEW,
    element: <TemplateView />
  },
  {
    path: PATHS.TEMPLATES_EDIT,
    element: <TemplateForm />
  },
  {
    path: PATHS.DESIGNS,
    element: <DesignsPage />,
    loader: () => DesignsLoader()
  },
  {
    path: PATHS.DESIGNS_VIEW,
    element: <DesignView />
  },
  {
    path: PATHS.DESIGNS_EDIT,
    element: <DesignForm />
  },
  {
    path: PATHS.FONTS,
    element: <FontsPage />,
    loader: () => FontsLoader()
  },
  {
    path: PATHS.FONTS_VIEW,
    element: <FontView />
  },
  {
    path: PATHS.FONTS_EDIT,
    element: <FontForm />
  },
  {
    path: PATHS.ORDERS,
    element: <OrdersPage />,
    loader: () => OrdersLoader()
  },
  {
    path: PATHS.ORDERS_VIEW,
    element: <OrderView />
  },
  {
    path: PATHS.ORDERS_EDIT,
    element: <OrderForm />
  },
  {
    path: PATHS.STORES,
    element: <StoresPage />,
    loader: () => StoresLoader()
  },
  {
    path: PATHS.STORE_VIEW,
    element: <StoreView />
  },
  {
    path: PATHS.STORE_EDIT,
    element: <StoreForm />
  },
  {
    path: PATHS.STORE_CONNECTOR,
    element: <StoreConnectorPage />,
    loader: () => StoreConnectorLoader()
  }
];
